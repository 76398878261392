// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var LogoPng = require("../images/logo.png");

var logo = LogoPng;

function VrsLogo(Props) {
  return React.createElement("img", {
              alt: "VR30.rent",
              height: "32",
              src: logo,
              width: "159"
            });
}

var make = VrsLogo;

exports.logo = logo;
exports.make = make;
/* logo Not a pure module */
