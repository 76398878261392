// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Picture = require("../Picture/Picture.bs.js");
var Spinner = require("../Spinner/Spinner.bs.js");
var DomUtils = require("../../../libs/DomUtils.bs.js");
var Cloudinary = require("../../../libs/Cloudinary.bs.js");
var ImageUtils = require("../../../libs/ImageUtils.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var NoPhotoIcon = require("../../icons/NoPhotoIcon.bs.js");
var ImageModuleScss = require("./Image.module.scss");

var css = ImageModuleScss;

function reducer(_state, _action) {
  return /* Ready */1;
}

function $$Image(Props) {
  var id = Props.id;
  var className = Props.className;
  var style = Props.style;
  var url = Props.url;
  var fetchWidth = Props.fetchWidth;
  var fetchHeight = Props.fetchHeight;
  var alt = Props.alt;
  var ariaHidden = Props.ariaHidden;
  var itemProp = Props.itemProp;
  var onClick = Props.onClick;
  var cropOpt = Props.crop;
  var crop = cropOpt !== undefined ? cropOpt : /* Fill */2;
  var match = React.useReducer(reducer, /* Loading */0);
  var dispatch = match[1];
  var urlWithTransformations = React.useMemo((function () {
          return Picture.buildUrl(url, crop, fetchWidth, fetchHeight);
        }), /* tuple */[
        url,
        crop,
        fetchWidth,
        fetchHeight
      ]);
  React.useEffect((function () {
          ImageUtils.loadAndThen(urlWithTransformations, (function (param) {
                  return Curry._1(dispatch, /* SetReady */0);
                }));
          
        }), ([]));
  if (match[0]) {
    var tmp = {
      alt: alt,
      src: urlWithTransformations
    };
    if (ariaHidden !== undefined) {
      tmp["aria-hidden"] = Caml_option.valFromOption(ariaHidden);
    }
    if (className !== undefined) {
      tmp.className = Caml_option.valFromOption(className);
    }
    if (id !== undefined) {
      tmp.id = Caml_option.valFromOption(id);
    }
    if (style !== undefined) {
      tmp.style = Caml_option.valFromOption(style);
    }
    if (itemProp !== undefined) {
      tmp.itemProp = Caml_option.valFromOption(itemProp);
    }
    var tmp$1 = onClick !== undefined ? (function ($$event) {
          return DomUtils.handleIfSpaceOrEnterKey(onClick, $$event);
        }) : undefined;
    if (tmp$1 !== undefined) {
      tmp.onKeyUp = Caml_option.valFromOption(tmp$1);
    }
    var tmp$2 = onClick !== undefined ? (function (_event) {
          return Curry._1(onClick, undefined);
        }) : undefined;
    if (tmp$2 !== undefined) {
      tmp.onClick = Caml_option.valFromOption(tmp$2);
    }
    return React.createElement("img", tmp);
  }
  var tmp$3 = {
    alt: alt,
    src: "/listing_tile_photo_loading_background.svg"
  };
  if (className !== undefined) {
    tmp$3.className = Caml_option.valFromOption(className);
  }
  return React.createElement("div", {
              className: css.loadingWrapper
            }, React.createElement("img", tmp$3), React.createElement(Spinner.make, {
                  size: /* LG */2,
                  color: /* Teal */1
                }));
}

function Image$Background(Props) {
  var id = Props.id;
  var classNameOpt = Props.className;
  var url = Props.url;
  var width = Props.width;
  var height = Props.height;
  var onClick = Props.onClick;
  var cropOpt = Props.crop;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var crop = cropOpt !== undefined ? cropOpt : /* Fill */2;
  var match = React.useReducer(reducer, /* Loading */0);
  var dispatch = match[1];
  var urlWithTransformations = React.useMemo((function () {
          return Picture.buildUrl(url, crop, width, height);
        }), /* tuple */[
        url,
        crop,
        width,
        height
      ]);
  React.useEffect((function () {
          ImageUtils.loadAndThen(urlWithTransformations, (function (param) {
                  return Curry._1(dispatch, /* SetReady */0);
                }));
          
        }), ([]));
  if (!match[0]) {
    return React.createElement("div", {
                className: Cn.$plus(Cn.$plus(css.backgroundImage, css.backgroundImageLoadingContainer), className)
              }, React.createElement(NoPhotoIcon.make, {
                    size: /* XL */4,
                    color: /* LightGray */9
                  }), React.createElement("div", {
                    className: css.backgroundImageLoadingSpinner
                  }, React.createElement(Spinner.make, {
                        size: /* LG */2,
                        color: /* Gray */3
                      })));
  }
  var tmp = {
    className: Cn.$plus(css.backgroundImage, className),
    style: {
      backgroundImage: "url(" + (String(urlWithTransformations) + ")")
    }
  };
  if (id !== undefined) {
    tmp.id = Caml_option.valFromOption(id);
  }
  var tmp$1 = onClick !== undefined ? (function ($$event) {
        return DomUtils.handleIfSpaceOrEnterKey(onClick, $$event);
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.onKeyUp = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = onClick !== undefined ? (function (_event) {
        return Curry._1(onClick, undefined);
      }) : undefined;
  if (tmp$2 !== undefined) {
    tmp.onClick = Caml_option.valFromOption(tmp$2);
  }
  return React.createElement("div", tmp);
}

var Background = {
  make: Image$Background
};

function makeJs(props) {
  var crop = props.crop;
  return $$Image({
              id: Caml_option.nullable_to_opt(props.id),
              className: Caml_option.nullable_to_opt(props.className),
              style: Caml_option.nullable_to_opt(props.style),
              url: props.url,
              fetchWidth: props.fetchWidth,
              fetchHeight: props.fetchHeight,
              alt: props.alt,
              ariaHidden: Caml_option.nullable_to_opt(props.ariaHidden),
              itemProp: Caml_option.nullable_to_opt(props.itemProp),
              onClick: Caml_option.nullable_to_opt(props.onClick),
              crop: (crop == null) ? /* Fill */2 : Curry._1(Cloudinary.Transformations.Crop.fromJs, crop)
            });
}

var make = $$Image;

exports.css = css;
exports.reducer = reducer;
exports.make = make;
exports.Background = Background;
exports.makeJs = makeJs;
/* css Not a pure module */
