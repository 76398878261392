// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Icon = require("../components/Icon/Icon.bs.js");
var React = require("react");

function NoPhotoIcon(Props) {
  var size = Props.size;
  var color = Props.color;
  var titleOpt = Props.title;
  var title = titleOpt !== undefined ? titleOpt : "No photo yet";
  return React.createElement(Icon.make, {
              title: title,
              size: size,
              children: React.createElement("g", {
                    fillRule: "evenodd"
                  }, React.createElement("path", {
                        style: Icon.style(color),
                        d: "M12.332 4c-.183 0-.44-.108-.57-.237l-1.524-1.526c-.13-.13-.39-.237-.572-.237H6.334c-.184 0-.442.108-.57.237L4.236 3.763c-.13.13-.388.237-.57.237H1.333C.573 3.895 0 4.46 0 5v7.667C0 13.434.573 14 1.333 14h13.334c.76 0 1.333-.566 1.333-1.333V5c0-.54-.573-1.105-1.333-1h-2.335zm2.335 9.333H1.333c-.38 0-.666-.282-.666-.666V5.333c0-.503.286-.784.666-.666H4c.184 0 .44-.108.566-.238 0 0 1.728-1.77 1.767-1.77h3.334c.122 0 1.776 1.762 1.776 1.762.123.13.372.237.556.237h2.66c.38-.118.666.163.666.666v7.334c0 .386-.286.668-.666.668z"
                      }), React.createElement("path", {
                        style: Icon.style(color),
                        d: "M8.167 4.333C5.865 4.333 4 6.198 4 8.5c0 2.3 1.865 4.167 4.167 4.167 2.3 0 4.166-1.866 4.166-4.168s-1.865-4.17-4.166-4.17zm0 7.667c-1.93 0-3.5-1.57-3.5-3.5S6.237 5 8.167 5s3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"
                      }), React.createElement("path", {
                        style: Icon.style(color),
                        d: "M8.167 5.667c-1.565 0-2.834 1.268-2.834 2.832 0 1.56 1.27 2.83 2.834 2.83C9.732 11.33 11 10.06 11 8.5c0-1.565-1.268-2.833-2.833-2.833zm0 5C6.972 10.667 6 9.694 6 8.5c0-1.195.972-2.167 2.167-2.167 1.194 0 2.166.972 2.166 2.166s-.973 2.16-2.166 2.16z"
                      }))
            });
}

var make = NoPhotoIcon;

exports.make = make;
/* Icon Not a pure module */
