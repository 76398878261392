// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromInt(x) {
  switch (x) {
    case 0 :
        return /* h0 */23240;
    case 1 :
        return /* h1 */23241;
    case 2 :
        return /* h2 */23242;
    case 3 :
        return /* h3 */23243;
    case 4 :
        return /* h4 */23244;
    case 5 :
        return /* h5 */23245;
    case 6 :
        return /* h6 */23246;
    case 7 :
        return /* h7 */23247;
    case 8 :
        return /* h8 */23248;
    case 9 :
        return /* h9 */23249;
    case 10 :
        return /* h10 */5182791;
    case 11 :
        return /* h11 */5182792;
    case 12 :
        return /* h12 */5182793;
    case 13 :
        return /* h13 */5182794;
    case 14 :
        return /* h14 */5182795;
    case 15 :
        return /* h15 */5182796;
    case 16 :
        return /* h16 */5182797;
    case 17 :
        return /* h17 */5182798;
    case 18 :
        return /* h18 */5182799;
    case 19 :
        return /* h19 */5182800;
    case 20 :
        return /* h20 */5183014;
    case 21 :
        return /* h21 */5183015;
    case 22 :
        return /* h22 */5183016;
    case 23 :
        return /* h23 */5183017;
    default:
      return Pervasives.failwith("Invalid hour value: " + (String(x) + ""));
  }
}

function fromJson(json) {
  return fromInt(Json_decode.$$int(json));
}

function toString(x) {
  if (x >= 5182793) {
    if (x >= 5182799) {
      if (x >= 5183014) {
        if (x >= 5183016) {
          if (x >= 5183017) {
            return "11:00 PM";
          } else {
            return "10:00 PM";
          }
        } else if (x >= 5183015) {
          return "9:00 PM";
        } else {
          return "8:00 PM";
        }
      } else if (x >= 5182800) {
        return "7:00 PM";
      } else {
        return "6:00 PM";
      }
    } else if (x >= 5182795) {
      if (x >= 5182797) {
        if (x >= 5182798) {
          return "5:00 PM";
        } else {
          return "4:00 PM";
        }
      } else if (x >= 5182796) {
        return "3:00 PM";
      } else {
        return "2:00 PM";
      }
    } else if (x >= 5182794) {
      return "1:00 PM";
    } else {
      return "12:00 PM";
    }
  } else if (x >= 23246) {
    if (x >= 23248) {
      if (x >= 5182791) {
        if (x >= 5182792) {
          return "11:00 AM";
        } else {
          return "10:00 AM";
        }
      } else if (x >= 23249) {
        return "9:00 AM";
      } else {
        return "8:00 AM";
      }
    } else if (x >= 23247) {
      return "7:00 AM";
    } else {
      return "6:00 AM";
    }
  } else if (x >= 23242) {
    if (x >= 23244) {
      if (x >= 23245) {
        return "5:00 AM";
      } else {
        return "4:00 AM";
      }
    } else if (x >= 23243) {
      return "3:00 AM";
    } else {
      return "2:00 AM";
    }
  } else if (x >= 23241) {
    return "1:00 AM";
  } else {
    return "12:00 AM";
  }
}

exports.fromInt = fromInt;
exports.fromJson = fromJson;
exports.toString = toString;
/* No side effect */
