// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Gatsby = require("../vendor/Gatsby.bs.js");
var Gatsby$1 = require("gatsby");
var VrsLogo = require("./VrsLogo.bs.js");
var VrsHeaderModuleScss = require("./VrsHeader.module.scss");

var css = VrsHeaderModuleScss;

function VrsHeader(Props) {
  return React.createElement("div", {
              className: css.container
            }, React.createElement("div", {
                  className: css.content
                }, React.createElement("div", {
                      className: css.labelContainer
                    }, React.createElement(Gatsby$1.Link, Gatsby.Link.makeProps("/", undefined, undefined, React.createElement(VrsLogo.make, { }), undefined))), React.createElement("div", {
                      className: css.linksContainer
                    }, React.createElement("div", {
                          className: css.linkWrapper
                        }, React.createElement("a", {
                              className: css.link,
                              href: "#story"
                            }, "About")), React.createElement("div", {
                          className: css.linkWrapper
                        }, React.createElement("a", {
                              className: css.link,
                              href: "#faq"
                            }, "FAQ")))));
}

var make = VrsHeader;

exports.css = css;
exports.make = make;
/* css Not a pure module */
