// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var RegularButton = require("./RegularButton.bs.js");
var RegularButtonAsLink = require("./RegularButtonAsLink.bs.js");
var RegularButtonActionless = require("./RegularButtonActionless.bs.js");
var RegularButtonAsNativeLink = require("./RegularButtonAsNativeLink.bs.js");

var AsLink = RegularButtonAsLink;

var AsNativeLink = RegularButtonAsNativeLink;

var Actionless = RegularButtonActionless;

var css = RegularButton.css;

var make = RegularButton.make;

exports.css = css;
exports.make = make;
exports.AsLink = AsLink;
exports.AsNativeLink = AsNativeLink;
exports.Actionless = Actionless;
/* RegularButton Not a pure module */
