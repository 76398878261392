// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var React = require("react");
var SpinnerModuleScss = require("./Spinner.module.scss");

var css = SpinnerModuleScss;

function px(value) {
  return String(value) + "px";
}

function getColorClassName(color) {
  switch (color) {
    case /* Orange */0 :
        return css.colorOrange;
    case /* Teal */1 :
        return css.colorTeal;
    case /* Silver */2 :
        return css.colorSilver;
    case /* Gray */3 :
        return css.colorGray;
    case /* White */4 :
        return css.colorWhite;
    
  }
}

function buildContainerClassName(bg, centered) {
  var bgClassName = bg !== undefined ? (
      bg ? css.bgOpaqueWhite : css.bgWhite
    ) : "";
  if (centered) {
    return css.centeredContainer;
  } else {
    return Cn.$plus(Cn.$plus(css.container, bgClassName), "fng-spinner");
  }
}

function buildStyle(spinnerSize, marginSize) {
  return {
          height: String(spinnerSize) + "px",
          margin: String(marginSize) + "px",
          overflow: "hidden",
          width: String(spinnerSize) + "px",
          opacity: "0.4",
          borderRadius: "50%"
        };
}

function Spinner(Props) {
  var size = Props.size;
  var color = Props.color;
  var bg = Props.bg;
  var centeredOpt = Props.centered;
  var centered = centeredOpt !== undefined ? centeredOpt : true;
  var colorClassName = getColorClassName(color);
  var spinnerSize = size >= 2 ? 10 : 6;
  var marginSize = size >= 2 ? 4 : 2;
  return React.createElement("div", {
              className: buildContainerClassName(bg, centered)
            }, React.createElement("div", {
                  className: centered ? css.centeredWrapper : css.wrapper
                }, React.createElement("span", {
                      className: Cn.$plus(css.dotLeft, colorClassName),
                      style: buildStyle(spinnerSize, marginSize)
                    }), React.createElement("span", {
                      className: Cn.$plus(css.dotMiddle, colorClassName),
                      style: buildStyle(spinnerSize, marginSize)
                    }), React.createElement("span", {
                      className: Cn.$plus(css.dotRight, colorClassName),
                      style: buildStyle(spinnerSize, marginSize)
                    })));
}

var make = Spinner;

exports.css = css;
exports.px = px;
exports.getColorClassName = getColorClassName;
exports.buildContainerClassName = buildContainerClassName;
exports.buildStyle = buildStyle;
exports.make = make;
/* css Not a pure module */
