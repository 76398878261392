// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function Svg(Props) {
  var title = Props.title;
  var viewBoxWidth = Props.viewBoxWidth;
  var viewBoxHeight = Props.viewBoxHeight;
  var className = Props.className;
  var children = Props.children;
  var id = "icon-title-" + (String(title) + "");
  var viewBox = "0 0 " + (viewBoxWidth + (" " + viewBoxHeight));
  return React.createElement("svg", {
              "aria-labelledby": id,
              className: className,
              viewBox: viewBox,
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("title", {
                  id: id
                }, title), children);
}

var make = Svg;

exports.make = make;
/* react Not a pure module */
