// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function inflect(x, one, many) {
  if (x !== 1) {
    return String(x) + (" " + many);
  } else {
    return "1 " + one;
  }
}

function inflectFloat(x, one, many) {
  if (x !== 1.0) {
    return String(x) + (" " + many);
  } else {
    return "1 " + one;
  }
}

var currency = "USD";

exports.currency = currency;
exports.inflect = inflect;
exports.inflectFloat = inflectFloat;
/* No side effect */
