// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Btn = require("./Btn.bs.js");
var React = require("react");
var Spinner = require("../Spinner/Spinner.bs.js");
var WithTestId = require("../WithTestId/WithTestId.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ButtonModuleScss = require("./Button.module.scss");

var css = ButtonModuleScss;

function RegularButton(Props) {
  var size = Props.size;
  var color = Props.color;
  var submitOpt = Props.submit;
  var margin = Props.margin;
  var ghostOpt = Props.ghost;
  var expandedOpt = Props.expanded;
  var disabledOpt = Props.disabled;
  var visuallyDisabledOpt = Props.visuallyDisabled;
  var busyOpt = Props.busy;
  var classNameOpt = Props.className;
  var testId = Props.testId;
  var ariaLabel = Props.ariaLabel;
  var onClick = Props.onClick;
  var onKeyDown = Props.onKeyDown;
  var children = Props.children;
  var submit = submitOpt !== undefined ? submitOpt : false;
  var ghost = ghostOpt !== undefined ? ghostOpt : false;
  var expanded = expandedOpt !== undefined ? expandedOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var visuallyDisabled = visuallyDisabledOpt !== undefined ? visuallyDisabledOpt : false;
  var busy = busyOpt !== undefined ? busyOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: Btn.className(size, color, ghost, expanded, margin, visuallyDisabled, busy, className),
    disabled: disabled || visuallyDisabled,
    type: submit ? "submit" : "button"
  };
  if (ariaLabel !== undefined) {
    tmp["aria-label"] = Caml_option.valFromOption(ariaLabel);
  }
  if (onKeyDown !== undefined) {
    tmp.onKeyDown = Caml_option.valFromOption(onKeyDown);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement(WithTestId.Optional.make, {
              id: testId,
              children: React.createElement("button", tmp, children, busy ? React.createElement(Spinner.make, {
                          size: Btn.spinnerSize(size),
                          color: Btn.spinnerColor(color, ghost),
                          centered: true
                        }) : null)
            });
}

var make = RegularButton;

exports.css = css;
exports.make = make;
/* css Not a pure module */
