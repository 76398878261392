// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var $$Screen = require("../../styles/Screen.bs.js");
var Cloudinary = require("../../../libs/Cloudinary.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var BuildPhotoUrlJs = require("app/libs/utils/fng/photos/buildPhotoUrl.js");

function buildCloudinaryUrl(url, crop, width, height) {
  return Cloudinary.Url.build(/* Auto */2, /* :: */[
              /* Dpr */1,
              /* :: */[
                /* Quality */0,
                /* :: */[
                  /* Crop */Block.__(3, [crop]),
                  /* :: */[
                    /* FetchFormat */Block.__(2, [/* Auto */2]),
                    /* :: */[
                      /* Width */Block.__(0, [width]),
                      /* :: */[
                        /* Height */Block.__(1, [height]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ], url);
}

function buildUrl(url, crop, width, height) {
  if (url.includes("images.hawaiichee.com")) {
    return BuildPhotoUrlJs.buildCloudfrontPhotoUrl({
                url: url,
                height: height,
                width: width
              });
  } else {
    return buildCloudinaryUrl(url, crop, width, height);
  }
}

function Picture(Props) {
  var src = Props.src;
  var desktop = Props.desktop;
  var tablet = Props.tablet;
  var mobile = Props.mobile;
  var cropOpt = Props.crop;
  var className = Props.className;
  var crop = cropOpt !== undefined ? cropOpt : /* Fill */2;
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return React.createElement("picture", tmp, mobile !== undefined ? React.createElement("source", {
                    media: "(max-width: " + (String($$Screen.sm) + "px)"),
                    srcSet: buildUrl(src, crop, mobile[0], mobile[1])
                  }) : null, tablet !== undefined ? React.createElement("source", {
                    media: "(max-width: " + (String($$Screen.md) + "px)"),
                    srcSet: buildUrl(src, crop, tablet[0], tablet[1])
                  }) : null, React.createElement("img", {
                  src: buildUrl(src, crop, desktop[0], desktop[1])
                }));
}

var Crop;

var make = Picture;

exports.Crop = Crop;
exports.buildCloudinaryUrl = buildCloudinaryUrl;
exports.buildUrl = buildUrl;
exports.make = make;
/* react Not a pure module */
