// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromString(param) {
  switch (param) {
    case "apartment" :
        return /* Apartment */0;
    case "bnb" :
        return /* Bnb */2;
    case "boat" :
        return /* Boat */8;
    case "bungalow" :
        return /* Bungalow */6;
    case "camper" :
        return /* Camper */9;
    case "condominium" :
        return /* Condominium */5;
    case "house" :
        return /* House */1;
    case "loft" :
        return /* Loft */3;
    case "other" :
        return /* Other */10;
    case "townhouse" :
        return /* Townhouse */4;
    case "villa" :
        return /* Villa */7;
    default:
      return /* Other */10;
  }
}

function fromJson(json) {
  return fromString(Json_decode.string(json));
}

function label(param) {
  switch (param) {
    case /* Apartment */0 :
        return "Apartment";
    case /* House */1 :
        return "House";
    case /* Bnb */2 :
        return "Bed & Breakfast";
    case /* Loft */3 :
        return "Loft";
    case /* Townhouse */4 :
        return "Townhouse";
    case /* Condominium */5 :
        return "Condominium";
    case /* Bungalow */6 :
        return "Bungalow";
    case /* Villa */7 :
        return "Villa";
    case /* Boat */8 :
        return "Boat";
    case /* Camper */9 :
        return "Camper/RV";
    case /* Other */10 :
        return "Other";
    
  }
}

exports.fromString = fromString;
exports.fromJson = fromJson;
exports.label = label;
/* No side effect */
