// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function isEnterKey($$event) {
  return $$event.keyCode === 13;
}

function isSpaceKey($$event) {
  return $$event.keyCode === 32;
}

function isSpaceOrEnterKey($$event) {
  if ($$event.keyCode === 32) {
    return true;
  } else {
    return $$event.keyCode === 13;
  }
}

function handleIfSpaceOrEnterKey(handler, $$event) {
  if (isSpaceOrEnterKey($$event)) {
    return Curry._1(handler, undefined);
  }
  
}

exports.isEnterKey = isEnterKey;
exports.isSpaceKey = isSpaceKey;
exports.isSpaceOrEnterKey = isSpaceOrEnterKey;
exports.handleIfSpaceOrEnterKey = handleIfSpaceOrEnterKey;
/* No side effect */
