// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Date = require("../libs/Date.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");

function toString(provider) {
  switch (provider) {
    case /* HawaiiChee */0 :
        return "hc";
    case /* Airbnb */1 :
        return "airbnb";
    case /* Vrbo */2 :
        return "vrbo";
    
  }
}

function fromString(provider) {
  var provider$1 = provider.toLowerCase();
  switch (provider$1) {
    case "airbnb" :
        return /* Ok */Block.__(0, [/* Airbnb */1]);
    case "hc" :
        return /* Ok */Block.__(0, [/* HawaiiChee */0]);
    case "vrbo" :
        return /* Ok */Block.__(0, [/* Vrbo */2]);
    default:
      return /* Error */Block.__(1, [provider$1]);
  }
}

function fromJson(json) {
  return Belt_Result.getExn(fromString(Json_decode.string(json)));
}

function toView(param) {
  switch (param) {
    case /* HawaiiChee */0 :
        return "HiChee";
    case /* Airbnb */1 :
        return "Airbnb";
    case /* Vrbo */2 :
        return "VRBO";
    
  }
}

function toListingTileLabel(provider) {
  switch (provider) {
    case /* HawaiiChee */0 :
        return "Direct to host";
    case /* Airbnb */1 :
        return "Airbnb";
    case /* Vrbo */2 :
        return "VRBO";
    
  }
}

function toRspecClassname(param) {
  switch (param) {
    case /* HawaiiChee */0 :
        return "hc";
    case /* Airbnb */1 :
        return "airbnb";
    case /* Vrbo */2 :
        return "vrbo";
    
  }
}

var Provider = {
  toString: toString,
  fromString: fromString,
  fromJson: fromJson,
  toView: toView,
  toListingTileLabel: toListingTileLabel,
  toRspecClassname: toRspecClassname
};

function fromJs(js) {
  return {
          rent: js.rent,
          pricePerNight: js.pricePerNight,
          avgPricePerNight: js.avgPricePerNight,
          turnoverFeesAndTaxes: js.turnoverFeesAndTaxes,
          providerFee: js.providerFee,
          bestPriceSkipped: js.bestPriceSkipped,
          total: js.total
        };
}

function fromJson$1(json) {
  return {
          rent: Json_decode.field("rent", Json_decode.$$float, json),
          pricePerNight: Json_decode.field("pricePerNight", Json_decode.$$float, json),
          avgPricePerNight: Json_decode.field("avgPricePerNight", Json_decode.$$float, json),
          turnoverFeesAndTaxes: Json_decode.field("turnoverFeesAndTaxes", Json_decode.$$float, json),
          providerFee: Json_decode.field("providerFee", Json_decode.$$float, json),
          bestPriceSkipped: Json_decode.field("bestPriceSkipped", Json_decode.bool, json),
          total: Json_decode.field("total", Json_decode.$$float, json)
        };
}

var Quote = {
  fromJs: fromJs,
  fromJson: fromJson$1
};

function fromJs$1(js) {
  return {
          name: Belt_Result.getExn(fromString(js.provider)),
          url: js.url,
          quote: Belt_Option.map(Caml_option.nullable_to_opt(js.quote), fromJs)
        };
}

function fromJson$2(json) {
  return {
          name: Json_decode.field("provider", fromJson, json),
          url: Json_decode.field("url", Json_decode.string, json),
          quote: Json_decode.field("quote", (function (param) {
                  return Json_decode.optional(fromJson$1, param);
                }), json)
        };
}

function keepWithQuote(providers) {
  return Belt_Array.keep(providers, (function (provider) {
                return Belt_Option.isSome(provider.quote);
              }));
}

function sortByTotal(providers) {
  return Belt_SortArray.stableSortBy(providers, (function (p1, p2) {
                if (p1.name === 0) {
                  return -1;
                }
                var q1 = p1.quote;
                if (p2.name === 0) {
                  return 1;
                }
                if (q1 === undefined) {
                  if (p2.quote !== undefined) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
                var q2 = p2.quote;
                if (q2 !== undefined) {
                  if (q1.total > q2.total) {
                    return 1;
                  } else if (q1.total < q2.total) {
                    return -1;
                  } else {
                    return 0;
                  }
                } else {
                  return -1;
                }
              }));
}

var ProviderBag = {
  fromJs: fromJs$1,
  fromJson: fromJson$2,
  keepWithQuote: keepWithQuote,
  sortByTotal: sortByTotal
};

function fromQuote(provider, providers) {
  return Belt_Option.flatMap(provider, (function (provider) {
                var match = Belt_Array.reduce(providers, [], (function (acc, provider) {
                        var match = provider.name;
                        var match$1 = provider.quote;
                        if (match !== 0 && match$1 !== undefined) {
                          return Belt_Array.concat(acc, [{
                                        provider: match,
                                        total: match$1.total
                                      }]);
                        } else {
                          return acc;
                        }
                      }));
                switch (provider) {
                  case /* HawaiiChee */0 :
                      if (match.length === 0) {
                        return /* HawaiiCheeOnly */0;
                      }
                      var hc = Belt_Array.getBy(providers, (function (provider) {
                              var match = provider.name;
                              return match === 0;
                            }));
                      var sorted = Belt_SortArray.stableSortBy(match, (function (p1, p2) {
                              return Caml_primitive.caml_float_compare(p1.total, p2.total);
                            }));
                      var match$1 = sorted[0];
                      if (hc === undefined) {
                        return ;
                      }
                      var match$2 = hc.quote;
                      if (match$2 !== undefined) {
                        return /* HawaiiChee */Block.__(0, [/* profit */match$1.total - match$2.total]);
                      } else {
                        return ;
                      }
                  case /* Airbnb */1 :
                      return /* ThirdPartyProvider */Block.__(1, [/* Airbnb */1038773484]);
                  case /* Vrbo */2 :
                      return /* ThirdPartyProvider */Block.__(1, [/* Vrbo */959393833]);
                  
                }
              }));
}

function fromSampleData(providers) {
  var sorted = Belt_SortArray.stableSortBy(providers, (function (param, param$1) {
          var x2 = param$1.total;
          var x1 = param.total;
          if (param.provider === 0 && x1 === x2) {
            return -1;
          }
          if (param$1.provider !== 0 || x1 !== x2) {
            return Caml_primitive.caml_float_compare(x1, x2);
          } else {
            return 1;
          }
        }));
  if (sorted.length === 0) {
    return ;
  }
  var match = sorted[0];
  var match$1 = Belt_Array.get(sorted, 1);
  switch (match.provider) {
    case /* HawaiiChee */0 :
        if (match$1 !== undefined) {
          return /* HawaiiChee */Block.__(0, [/* profit */match$1.total - match.total]);
        } else {
          return /* HawaiiCheeOnly */0;
        }
    case /* Airbnb */1 :
        return /* ThirdPartyProvider */Block.__(1, [/* Airbnb */1038773484]);
    case /* Vrbo */2 :
        return /* ThirdPartyProvider */Block.__(1, [/* Vrbo */959393833]);
    
  }
}

var BestPrice = {
  fromQuote: fromQuote,
  fromSampleData: fromSampleData
};

function fromJson$3(json) {
  return {
          hc: Json_decode.field("hc", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          vrbo: Json_decode.field("vrbo", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          airbnb: Json_decode.field("airbnb", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json)
        };
}

function getProviderWithPrice(quotedPrice, provider) {
  if (quotedPrice !== undefined) {
    return {
            name: provider,
            price: quotedPrice
          };
  }
  
}

function toArray(quotedPrices) {
  return [
          getProviderWithPrice(quotedPrices.hc, /* HawaiiChee */0),
          getProviderWithPrice(quotedPrices.airbnb, /* Airbnb */1),
          getProviderWithPrice(quotedPrices.vrbo, /* Vrbo */2)
        ];
}

var QuotedPrices = {
  fromJson: fromJson$3,
  getProviderWithPrice: getProviderWithPrice,
  toArray: toArray
};

function sortByTotal$1(providers) {
  return Belt_SortArray.stableSortBy(providers, (function (p1, p2) {
                var q1 = p1.totalPrice;
                if (q1 === undefined) {
                  if (p2.totalPrice !== undefined) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
                var q2 = p2.totalPrice;
                if (q2 !== undefined) {
                  if (q1 > q2) {
                    return 1;
                  } else if (q1 < q2) {
                    return -1;
                  } else {
                    return 0;
                  }
                } else {
                  return -1;
                }
              }));
}

function fromProviderBag(providerBag) {
  var quote = providerBag.quote;
  var quote$1 = providerBag.quote;
  return {
          name: providerBag.name,
          pricePerNight: quote !== undefined ? quote.pricePerNight : undefined,
          totalPrice: quote$1 !== undefined ? quote$1.total : undefined
        };
}

function getTotalPriceForProvider(price, numberOfNights) {
  return price * numberOfNights;
}

function fromQuotedPrices(quotedPrices, checkIn, checkOut) {
  var numberOfNights = checkIn !== undefined && checkOut !== undefined ? $$Date.Naive.differenceInCalendarDays(Caml_option.valFromOption(checkOut), Caml_option.valFromOption(checkIn)) : undefined;
  if (numberOfNights !== undefined && quotedPrices !== undefined) {
    return Belt_Array.map(Belt_Array.keepMap(toArray(quotedPrices), (function (provider) {
                      return provider;
                    })), (function (provider) {
                  return {
                          name: provider.name,
                          pricePerNight: provider.price,
                          totalPrice: Belt_Option.map(provider.price, (function (price) {
                                  return price * numberOfNights;
                                }))
                        };
                }));
  }
  
}

var SimplifiedProviderBag = {
  sortByTotal: sortByTotal$1,
  fromProviderBag: fromProviderBag,
  getTotalPriceForProvider: getTotalPriceForProvider,
  fromQuotedPrices: fromQuotedPrices
};

exports.Provider = Provider;
exports.Quote = Quote;
exports.ProviderBag = ProviderBag;
exports.BestPrice = BestPrice;
exports.QuotedPrices = QuotedPrices;
exports.SimplifiedProviderBag = SimplifiedProviderBag;
/* Date Not a pure module */
