// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

function makeProps(path, className, onClick, children, param) {
  var tmp = {
    to: path,
    children: children
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return tmp;
}

var Link = {
  makeProps: makeProps
};

exports.Link = Link;
/* No side effect */
