// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromString(rule) {
  switch (rule) {
    case "Child-friendly (3-12) years" :
        return /* Children */0;
    case "Events or parties allowed" :
        return /* Events */4;
    case "Infant-friendly (0-2) years" :
        return /* Infants */1;
    case "Smoking allowed" :
        return /* Smoking */3;
    case "Suitable for pets" :
        return /* Pets */2;
    default:
      return Pervasives.failwith("Unknown system house rule kind: " + (String(rule) + ""));
  }
}

function fromJson(json) {
  return fromString(Json_decode.string(json));
}

var Kind = {
  fromString: fromString,
  fromJson: fromJson
};

function fromString$1(status) {
  switch (status) {
    case "no" :
        return /* Disallowed */1;
    case "unspecified" :
        return /* Unspecified */2;
    case "yes" :
        return /* Allowed */0;
    default:
      return Pervasives.failwith("Unknown system house rule status: " + (String(status) + ""));
  }
}

function fromJson$1(json) {
  return fromString$1(Json_decode.string(json));
}

var Status = {
  fromString: fromString$1,
  fromJson: fromJson$1
};

function fromJs(js) {
  return {
          id: js.id,
          listingId: js.listingId,
          kind: fromString(js.name),
          name: js.name,
          status: fromString$1(js.status)
        };
}

function fromJson$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          listingId: Json_decode.field("listingId", Json_decode.$$int, json),
          kind: Json_decode.field("name", fromJson, json),
          name: Json_decode.field("name", Json_decode.string, json),
          status: Json_decode.field("status", fromJson$1, json)
        };
}

function display(rule) {
  var match = rule.kind;
  var match$1 = rule.status;
  switch (match) {
    case /* Children */0 :
        switch (match$1) {
          case /* Allowed */0 :
              return "Child-friendly (3-12 years)";
          case /* Disallowed */1 :
              return "No children (3-12 years)";
          case /* Unspecified */2 :
              return ;
          
        }
    case /* Infants */1 :
        switch (match$1) {
          case /* Allowed */0 :
              return "Infant-friendly (0-2 years)";
          case /* Disallowed */1 :
              return "No infants (0-2 years)";
          case /* Unspecified */2 :
              return ;
          
        }
    case /* Pets */2 :
        switch (match$1) {
          case /* Allowed */0 :
              return "Suitable for pets";
          case /* Disallowed */1 :
              return "Not suitable for pets";
          case /* Unspecified */2 :
              return ;
          
        }
    case /* Smoking */3 :
        switch (match$1) {
          case /* Allowed */0 :
              return "Smoking allowed";
          case /* Disallowed */1 :
              return "No smoking";
          case /* Unspecified */2 :
              return ;
          
        }
    case /* Events */4 :
        switch (match$1) {
          case /* Allowed */0 :
              return "Events or parties allowed";
          case /* Disallowed */1 :
              return "No events or parties";
          case /* Unspecified */2 :
              return ;
          
        }
    
  }
}

function toArray(rules) {
  return [
          rules.children,
          rules.infants,
          rules.pets,
          rules.smoking,
          rules.events
        ];
}

function fromJs$1(rules) {
  var children = {
    contents: undefined
  };
  var infants = {
    contents: undefined
  };
  var pets = {
    contents: undefined
  };
  var smoking = {
    contents: undefined
  };
  var events = {
    contents: undefined
  };
  Belt_Array.forEach(Belt_Array.map(rules, fromJs), (function (rule) {
          var match = rule.kind;
          switch (match) {
            case /* Children */0 :
                children.contents = rule;
                return ;
            case /* Infants */1 :
                infants.contents = rule;
                return ;
            case /* Pets */2 :
                pets.contents = rule;
                return ;
            case /* Smoking */3 :
                smoking.contents = rule;
                return ;
            case /* Events */4 :
                events.contents = rule;
                return ;
            
          }
        }));
  return {
          children: Belt_Option.getExn(children.contents),
          infants: Belt_Option.getExn(infants.contents),
          pets: Belt_Option.getExn(pets.contents),
          smoking: Belt_Option.getExn(smoking.contents),
          events: Belt_Option.getExn(events.contents)
        };
}

function fromJson$3(json) {
  var children = {
    contents: undefined
  };
  var infants = {
    contents: undefined
  };
  var pets = {
    contents: undefined
  };
  var smoking = {
    contents: undefined
  };
  var events = {
    contents: undefined
  };
  Belt_Array.forEach(Json_decode.array(fromJson$2, json), (function (rule) {
          var match = rule.kind;
          switch (match) {
            case /* Children */0 :
                children.contents = rule;
                return ;
            case /* Infants */1 :
                infants.contents = rule;
                return ;
            case /* Pets */2 :
                pets.contents = rule;
                return ;
            case /* Smoking */3 :
                smoking.contents = rule;
                return ;
            case /* Events */4 :
                events.contents = rule;
                return ;
            
          }
        }));
  return {
          children: Belt_Option.getExn(children.contents),
          infants: Belt_Option.getExn(infants.contents),
          pets: Belt_Option.getExn(pets.contents),
          smoking: Belt_Option.getExn(smoking.contents),
          events: Belt_Option.getExn(events.contents)
        };
}

var All = {
  toArray: toArray,
  fromJs: fromJs$1,
  fromJson: fromJson$3
};

exports.Kind = Kind;
exports.Status = Status;
exports.fromJs = fromJs;
exports.fromJson = fromJson$2;
exports.display = display;
exports.All = All;
/* No side effect */
