// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJs(js) {
  return {
          id: js.id,
          listingId: js.listingId,
          name: js.name
        };
}

function fromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          listingId: Json_decode.field("listingId", Json_decode.$$int, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

exports.fromJs = fromJs;
exports.fromJson = fromJson;
/* No side effect */
