// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Moveto = require("moveto");

function make(tolerance) {
  return new Moveto({
              tolerance: tolerance
            });
}

exports.make = make;
/* moveto Not a pure module */
