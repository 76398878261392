// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function preload(src) {
  var image = new Image();
  image.src = src;
  
}

function loadAndThen(src, fn) {
  var image = new Image();
  image.onload = fn;
  image.src = src;
  
}

exports.preload = preload;
exports.loadAndThen = loadAndThen;
/* No side effect */
