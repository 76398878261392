export { default as buildPhotoUrl } from './buildPhotoUrl';

const DEFAULT_MAX_WIDTH = 2500;
export const IMAGE_SIZE_LIMIT = 25000000;

// When changing photo sizes also update app/models/listing_score.rb
export const DEFAULT_RECOMMENDED_COVER_SIZE = [1600, 600];
export const DEFAULT_RECOMMENDED_COMMON_SIZE = 600;

export function isCoverPhotoHq(photo) {
  const [width, height] = DEFAULT_RECOMMENDED_COVER_SIZE;
  return photo.width >= width && photo.height >= height;
}

export function isCommonPhotoHq(photo) {
  return photo.width >= DEFAULT_RECOMMENDED_COMMON_SIZE && photo.height >= DEFAULT_RECOMMENDED_COMMON_SIZE;
}

export function toMegapixels(px) {
  return parseInt(px / 1000000, 10);
}

/**
 * @desc Returns actual DPR on the client.
 *       Most likely it will cause different DOM w/ server rendering.
 *
 * @return {number} - DPR: actual on the client and 1 on the server
 */
export function getDpr() {
  return typeof window !== 'undefined' && window.devicePixelRatio ? window.devicePixelRatio : 1;
}

export function calculatePhotoDimensions(width, height, maxWidth = DEFAULT_MAX_WIDTH) {
  return width <= maxWidth
    ? { width, height }
    : { width: maxWidth, height: Math.floor(height * (maxWidth / width)) };
}

/**
 * @desc Deprecated.
 *       Use `components/Image` & `buildPhotoUrl`
 *
 */
export function getPhotoUrl(photoUrl, width, height, resize = 'fit') {
  const transform = `w_${width},h_${height},c_${resize}`;
  return photoUrl.replace('upload', `upload/${transform}`).replace('facebook', `facebook/${transform}`);
}

export function readDataUrl(file, onLoad) {
  const reader = new FileReader();
  reader.onload = event => onLoad(event.target.result, file);
  reader.readAsDataURL(file);
}

export function buildCloudinaryFormData(data, file = null) {
  const { timestamp, signature, apiKey, publicId } = data;
  const formData = new FormData();
  formData.append('timestamp', timestamp);
  formData.append('api_key', apiKey);
  formData.append('signature', signature);

  if (file !== null) formData.append('file', file);
  if (publicId !== null) formData.append('public_id', publicId);

  return formData;
}

export function buildPhotoData({ cloudinaryData, position, ...rest }) {
  return {
    publicId: cloudinaryData.public_id,
    format: cloudinaryData.format,
    cloudUrl: cloudinaryData.url,
    width: cloudinaryData.width,
    height: cloudinaryData.height,
    position,
    ...rest,
  };
}

const MAX_DPR = 3;

export function isLimitExceeded(width, height) {
  const dpr = MAX_DPR;
  const imageSize = parseInt(width, 10) * parseInt(height, 10) * dpr ** 2;

  return imageSize > IMAGE_SIZE_LIMIT;
}

export function getReducedPhotoDimensions(width, height) {
  const aspectRatio = parseInt(width, 10) / parseInt(height, 10);
  const dpr = MAX_DPR;
  const newHeight = Math.floor(Math.sqrt(IMAGE_SIZE_LIMIT / (aspectRatio * dpr ** 2)));
  const newWidth = Math.floor(newHeight * aspectRatio);

  return { width: newWidth, height: newHeight };
}
