// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var VrsHeader = require("./VrsHeader.bs.js");
var VrsGallery = require("./VrsGallery.bs.js");
var VrsLayoutModuleScss = require("./VrsLayout.module.scss");

((require('../hc/js/styleguide/styles/layout/resets.scss')));

var css = VrsLayoutModuleScss;

function VrsLayout$default(Props) {
  var children = Props.children;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: css.wrapper
                }, React.createElement(VrsHeader.make, { }), React.createElement("main", {
                      role: "main"
                    }, children)), React.createElement(VrsGallery.make, { }));
}

var $$default = VrsLayout$default;

exports.css = css;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/*  Not a pure module */
