// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function WithTestId(Props) {
  var id = Props.id;
  var children = Props.children;
  return React.cloneElement(children, {
              "data-test-id": id
            });
}

function WithTestId$Optional(Props) {
  var id = Props.id;
  var children = Props.children;
  return React.cloneElement(children, id !== undefined ? ({
                  "data-test-id": id
                }) : ({ }));
}

var Optional = {
  make: WithTestId$Optional
};

var make = WithTestId;

exports.make = make;
exports.Optional = Optional;
/* react Not a pure module */
