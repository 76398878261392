// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJs(js) {
  return {
          id: js.id,
          firstName: js.firstName,
          lastName: js.lastName,
          aboutMeDetails: Caml_option.nullable_to_opt(js.aboutMeDetails),
          url: js.url,
          avatarUrl: js.avatarUrl,
          hostBestPrice: js.hostBestPrice,
          totalListingCount: js.totalListingCount
        };
}

function fromJson(json) {
  var isMetaowner = Json_decode.field("isMetaowner", Json_decode.bool, json);
  var id = Json_decode.field("id", Json_decode.$$int, json);
  if (isMetaowner) {
    return /* MetaOwnerClaimed */Block.__(2, [{
                id: Json_decode.field("id", Json_decode.$$int, json),
                firstName: Json_decode.field("firstName", Json_decode.string, json),
                lastName: Json_decode.field("lastName", Json_decode.string, json),
                avatarUrl: Json_decode.field("avatarUrl", Json_decode.string, json)
              }]);
  } else if (id !== 0) {
    return /* Owner */Block.__(0, [{
                id: Json_decode.field("id", Json_decode.$$int, json),
                firstName: Json_decode.field("firstName", Json_decode.string, json),
                lastName: Json_decode.field("lastName", Json_decode.string, json),
                aboutMeDetails: Json_decode.field("aboutMeDetails", (function (param) {
                        return Json_decode.optional(Json_decode.string, param);
                      }), json),
                url: Json_decode.field("url", Json_decode.string, json),
                avatarUrl: Json_decode.field("avatarUrl", Json_decode.string, json),
                hostBestPrice: Json_decode.field("hostBestPrice", Json_decode.bool, json),
                totalListingCount: Json_decode.field("totalListingCount", Json_decode.$$int, json)
              }]);
  } else {
    return /* MetaOwnerUnclaimed */Block.__(1, [{
                firstName: Json_decode.field("firstName", Json_decode.string, json),
                lastName: Json_decode.field("lastName", Json_decode.string, json),
                aboutMeDetails: Json_decode.field("aboutMeDetails", (function (param) {
                        return Json_decode.optional(Json_decode.string, param);
                      }), json),
                url: Json_decode.field("url", Json_decode.string, json),
                avatarUrl: Json_decode.field("avatarUrl", Json_decode.string, json),
                hostBestPrice: Json_decode.field("hostBestPrice", Json_decode.bool, json),
                totalListingCount: Json_decode.field("totalListingCount", Json_decode.$$int, json)
              }]);
  }
}

exports.fromJs = fromJs;
exports.fromJson = fromJson;
/* No side effect */
