// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Btn = require("./Btn.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ButtonModuleScss = require("./Button.module.scss");

var css = ButtonModuleScss;

function RegularButtonAsNativeLink(Props) {
  var href = Props.href;
  var targetBlank = Props.targetBlank;
  var size = Props.size;
  var color = Props.color;
  var margin = Props.margin;
  var ghostOpt = Props.ghost;
  var expandedOpt = Props.expanded;
  var visuallyDisabledOpt = Props.visuallyDisabled;
  var classNameOpt = Props.className;
  var onClick = Props.onClick;
  var children = Props.children;
  var ghost = ghostOpt !== undefined ? ghostOpt : false;
  var expanded = expandedOpt !== undefined ? expandedOpt : false;
  var visuallyDisabled = visuallyDisabledOpt !== undefined ? visuallyDisabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: Btn.className(size, color, ghost, expanded, margin, visuallyDisabled, false, className),
    href: href
  };
  var tmp$1 = targetBlank !== undefined && targetBlank ? "noopener noreferrer" : undefined;
  if (tmp$1 !== undefined) {
    tmp.rel = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = targetBlank !== undefined && targetBlank ? "_blank" : undefined;
  if (tmp$2 !== undefined) {
    tmp.target = Caml_option.valFromOption(tmp$2);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement("a", tmp, children);
}

var make = RegularButtonAsNativeLink;

exports.css = css;
exports.make = make;
/* css Not a pure module */
