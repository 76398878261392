// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var ButtonModuleScss = require("./Button.module.scss");

var css = ButtonModuleScss;

function className(size, color, ghost, expanded, margin, visuallyDisabled, busy, className$1) {
  var tmp;
  switch (size) {
    case /* SM */0 :
        tmp = css.sizeSm;
        break;
    case /* MD */1 :
        tmp = css.sizeMd;
        break;
    case /* LG */2 :
        tmp = css.sizeLg;
        break;
    
  }
  var tmp$1;
  switch (color) {
    case /* Primary */0 :
        tmp$1 = ghost ? css.colorGhostPrimary : css.colorPrimary;
        break;
    case /* Silver */1 :
        tmp$1 = ghost ? css.colorGhostSilver : css.colorSilver;
        break;
    case /* Teal */2 :
        tmp$1 = ghost ? css.colorGhostTeal : css.colorTeal;
        break;
    
  }
  return Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(Cn.$plus(css.button, tmp), tmp$1), Cn.mapSome(margin, (function (margin) {
                                    if (margin) {
                                      return css.marginLeft;
                                    } else {
                                      return css.marginRight;
                                    }
                                  }))), Cn.on(css.expanded, expanded)), Cn.on(css.visuallyDisabled, visuallyDisabled)), Cn.on(css.busy, busy)), className$1);
}

function spinnerSize(size) {
  return size;
}

function spinnerColor(color, ghost) {
  if (!ghost) {
    return /* White */4;
  }
  switch (color) {
    case /* Primary */0 :
        return /* Orange */0;
    case /* Silver */1 :
        return /* Silver */2;
    case /* Teal */2 :
        return /* Teal */1;
    
  }
}

exports.css = css;
exports.className = className;
exports.spinnerSize = spinnerSize;
exports.spinnerColor = spinnerColor;
/* css Not a pure module */
