// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromInt(x) {
  switch (x) {
    case -11 :
        return /* tzN11 */458927336;
    case -10 :
        return /* tzN10 */458927335;
    case -9 :
        return /* tzN9 */-855009487;
    case -8 :
        return /* tzN8 */-855009488;
    case -7 :
        return /* tzN7 */-855009489;
    case -6 :
        return /* tzN6 */-855009490;
    case -5 :
        return /* tzN5 */-855009491;
    case -4 :
        return /* tzN4 */-855009492;
    case -3 :
        return /* tzN3 */-855009493;
    case -2 :
        return /* tzN2 */-855009494;
    case -1 :
        return /* tzN1 */-855009495;
    case 0 :
        return /* tz0 */5795818;
    case 1 :
        return /* tzP1 */-855009049;
    case 2 :
        return /* tzP2 */-855009048;
    case 3 :
        return /* tzP3 */-855009047;
    case 4 :
        return /* tzP4 */-855009046;
    case 5 :
        return /* tzP5 */-855009045;
    case 6 :
        return /* tzP6 */-855009044;
    case 7 :
        return /* tzP7 */-855009043;
    case 8 :
        return /* tzP8 */-855009042;
    case 9 :
        return /* tzP9 */-855009041;
    case 10 :
        return /* tzP10 */459026793;
    case 11 :
        return /* tzP11 */459026794;
    default:
      return Pervasives.failwith("Invalid timezone value: " + (String(x) + ""));
  }
}

function fromJson(json) {
  return fromInt(Json_decode.$$int(json));
}

function toString(x) {
  if (x >= -855009047) {
    if (x >= -855009041) {
      if (x >= 458927335) {
        if (x >= 459026793) {
          if (x >= 459026794) {
            return "(UTC + 11:00)";
          } else {
            return "(UTC + 10:00)";
          }
        } else if (x >= 458927336) {
          return "(UTC - 11:00)";
        } else {
          return "(UTC - 10:00)";
        }
      } else if (x >= 5795818) {
        return "(UTC)";
      } else {
        return "(UTC + 9:00)";
      }
    } else if (x >= -855009045) {
      if (x >= -855009043) {
        if (x >= -855009042) {
          return "(UTC + 8:00)";
        } else {
          return "(UTC + 7:00)";
        }
      } else if (x >= -855009044) {
        return "(UTC + 6:00)";
      } else {
        return "(UTC + 5:00)";
      }
    } else if (x >= -855009046) {
      return "(UTC + 4:00)";
    } else {
      return "(UTC + 3:00)";
    }
  } else if (x >= -855009490) {
    if (x >= -855009488) {
      if (x >= -855009049) {
        if (x >= -855009048) {
          return "(UTC + 2:00)";
        } else {
          return "(UTC + 1:00)";
        }
      } else if (x >= -855009487) {
        return "(UTC - 9:00)";
      } else {
        return "(UTC - 8:00)";
      }
    } else if (x >= -855009489) {
      return "(UTC - 7:00)";
    } else {
      return "(UTC - 6:00)";
    }
  } else if (x !== -855009494) {
    if (x >= -855009492) {
      if (x >= -855009491) {
        return "(UTC - 5:00)";
      } else {
        return "(UTC - 4:00)";
      }
    } else if (x >= -855009493) {
      return "(UTC - 3:00)";
    } else {
      return "(UTC - 1:00)";
    }
  } else {
    return "(UTC - 2:00)";
  }
}

exports.fromInt = fromInt;
exports.fromJson = fromJson;
exports.toString = toString;
/* No side effect */
