// @flow
import _ from 'lodash/fp';
import ThumborUrlBuilder from 'thumbor-url-builder';

import type { CloudinaryTransformations, PhotoExtension } from 'types';

import { isLimitExceeded, getReducedPhotoDimensions } from './index';

const DEFAULT_TRANSFORMATIONS = {
  quality: 'auto',
  dpr: 'auto',
  fetchFormat: 'auto',
  crop: 'fit',
};

// pretty keys for FNG devs => cloudinary url keys
const TRANSFORMATION_KEY_MAP = {
  width: 'w_',
  height: 'h_',
  fetchFormat: 'f_',
  aspectRatio: 'ar_',
  crop: 'c_',
  radius: 'r_',
  dpr: 'dpr_',
  quality: 'q_',
  gravity: 'g_',
  x: 'x_',
  y: 'y_',
  effect: 'e_',
  saturation: 'e_saturation:',
  contrast: 'e_contrast:',
  background: 'b_',
  opacity: 'o_',
  art: 'e_art:',
  blur: 'e_blur:',
  tint: 'e_tint:',
  gradient_fade: 'e_gradient_fade:',
  colorize: 'e_colorize:',
};

const getCloudinaryTransformPrefix = _.cond([
  // $FlowFixMe
  [_.has(_, TRANSFORMATION_KEY_MAP), _.get(_, TRANSFORMATION_KEY_MAP)],
  [
    _.constant(true),
    transform => {
      throw new Error(`Unknown cloudinary transformation: '${transform}'`);
    },
  ],
]);

const validateTransformation = (transformation: CloudinaryTransformations) => {
  const { width, height } = transformation;

  if (!width || !height || !isLimitExceeded(width, height)) return transformation;

  return { ...transformation, ...getReducedPhotoDimensions(width, height) };
};

// Check if image fit to Cloudinary dimensions limit (25Mpx)
const validateDimensions = ({
  transforms,
  // $FlowFixMe: most of the CloudinaryTransformation types are unions and should be remade as spreads
  ...transformations
}: CloudinaryTransformations): CloudinaryTransformations =>
  transforms
    ? { transforms: _.map(transform => validateTransformation(transform), transforms) }
    : validateTransformation(transformations);

const buildTransform = (transformations: {}, { assignDefaults = false }: { assignDefaults: boolean }) =>
  _.flow(
    assignDefaults ? _.merge(DEFAULT_TRANSFORMATIONS) : _.identity,
    _.omitBy(_.isNil),
    _.entries,
    _.map(([key, value]) => getCloudinaryTransformPrefix(key) + value),
    _.join(','),
  )(transformations);

const buildFinalTransform = ({ transforms, ...transformations }: CloudinaryTransformations) =>
  transforms
    ? transforms.map(transform => buildTransform(transform, { assignDefaults: false })).join('/')
    : buildTransform(transformations, { assignDefaults: true });

const isImageUrlHasExtension = (url: string) => {
  const MAX_IMAGE_EXTENSION_LENGTH = 4; // jpeg, webp

  return url.substr(url.lastIndexOf('.') + 1, url.length).length <= MAX_IMAGE_EXTENSION_LENGTH;
};

const changeImageExtension = _.curry(
  // $FlowIgnoreMe: lodash typedefs
  (imageUrl: string, nextExtension: ?PhotoExtension) => {
    if (_.isNil(nextExtension)) {
      return imageUrl;
    }

    if (isImageUrlHasExtension(imageUrl)) {
      return `${imageUrl.substr(0, imageUrl.lastIndexOf('.'))}.${nextExtension}`;
    }
    return `${imageUrl}.${nextExtension}`;
  },
);

/**
 * Takes an object with a url to a photo on cloudinary plus optional transforms
 * and returns a ready-to-use url for your image tags and background-images.
 * `transforms` is a special key that indicates we want to chain transformations, while
 * `transformations` refers to an object containing cloudinary transformation key/values
 *
 * NOTE: no defaults are applied when using `transforms` and all other keys are ignored
 */
const buildCloudinaryPhotoUrl = ({
  url,
  format,
  ...transformations
}: {
  ...CloudinaryTransformations,
  url: string,
}): string =>
  // $FlowFixMe: most of the CloudinaryTransformation types are unions and should be remade as spreads
  _.flow(
    validateDimensions,
    buildFinalTransform,
    finalTransform =>
      url
        .replace('upload', `upload/${finalTransform}`)
        .replace('facebook', `facebook/${finalTransform}`)
        .replace('fetch', `fetch/${finalTransform}`),
    // $FlowFixMe
    changeImageExtension(_, format),
  )(transformations);

const awsImagesHandlingKey =
  process.env.AWS_IMAGES_HANDLING_KEY || process.env.GATSBY_AWS_IMAGES_HANDLING_KEY;
const awsImagesHandlingServer = process.env.AWS_IMAGES_HANDLING_SERVER || 'https://images.hawaiichee.com';

/**
 * Currently we keep only VRS listing's photos in AWS CloudFront/Lambda setup.
 * Thats why resizing is the only transformation needed here.
 */
export const buildCloudfrontPhotoUrl = ({
  url,
  height,
  width,
}: {
  url: string,
  height: string | number | void | null,
  width: string | number | void | null,
}) =>
  new ThumborUrlBuilder(awsImagesHandlingKey, awsImagesHandlingServer)
    .setImagePath(url.slice(awsImagesHandlingServer.length))
    .resize(width, height)
    .buildUrl();

const buildPhotoUrl = (attrs: { ...CloudinaryTransformations, url: string }) =>
  attrs.url.includes(awsImagesHandlingServer)
    ? buildCloudfrontPhotoUrl(attrs)
    : buildCloudinaryPhotoUrl(attrs);

export default buildPhotoUrl;
