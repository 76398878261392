// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Btn = require("./Btn.bs.js");
var React = require("react");
var ReactRouter = require("../../../bindings/ReactRouter.bs.js");
var ReactRouterDom = require("react-router-dom");
var ButtonModuleScss = require("./Button.module.scss");

var css = ButtonModuleScss;

function RegularButtonAsLink(Props) {
  var href = Props.href;
  var size = Props.size;
  var color = Props.color;
  var margin = Props.margin;
  var ghostOpt = Props.ghost;
  var expandedOpt = Props.expanded;
  var visuallyDisabledOpt = Props.visuallyDisabled;
  var classNameOpt = Props.className;
  var onClick = Props.onClick;
  var children = Props.children;
  var ghost = ghostOpt !== undefined ? ghostOpt : false;
  var expanded = expandedOpt !== undefined ? expandedOpt : false;
  var visuallyDisabled = visuallyDisabledOpt !== undefined ? visuallyDisabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(ReactRouterDom.Link, ReactRouter.Link.makeProps(href, Btn.className(size, color, ghost, expanded, margin, visuallyDisabled, false, className), onClick, children, undefined));
}

var make = RegularButtonAsLink;

exports.css = css;
exports.make = make;
/* css Not a pure module */
