// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Hour = require("./Hour.bs.js");
var Timezone = require("./Timezone.bs.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");

function fromJs(js) {
  return {
          id: js.id,
          phoneNumber: js.phoneNumber,
          confirmed: js.confirmed,
          confirmationSent: js.confirmationSent,
          preferredContactTimeStart: Belt_Option.map(Caml_option.nullable_to_opt(js.preferredContactTimeStart), Hour.fromInt),
          preferredContactTimeEnd: Belt_Option.map(Caml_option.nullable_to_opt(js.preferredContactTimeEnd), Hour.fromInt),
          preferredContactTimezone: Belt_Option.map(Caml_option.nullable_to_opt(js.preferredContactTimezone), Timezone.fromInt)
        };
}

function fromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          phoneNumber: Json_decode.field("phoneNumber", Json_decode.string, json),
          confirmed: Json_decode.field("confirmed", Json_decode.bool, json),
          confirmationSent: Json_decode.field("confirmationSent", Json_decode.bool, json),
          preferredContactTimeStart: Json_decode.field("preferredContactTimeStart", (function (param) {
                  return Json_decode.optional(Hour.fromJson, param);
                }), json),
          preferredContactTimeEnd: Json_decode.field("preferredContactTimeEnd", (function (param) {
                  return Json_decode.optional(Hour.fromJson, param);
                }), json),
          preferredContactTimezone: Json_decode.field("preferredContactTimezone", (function (param) {
                  return Json_decode.optional(Timezone.fromJson, param);
                }), json)
        };
}

exports.fromJs = fromJs;
exports.fromJson = fromJson;
/* No side effect */
