// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("bs-date-fns/src/DateFns.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/src/Json_encode.bs.js");
var Is_past = require("date-fns/is_past");
var Is_today = require("date-fns/is_today");
var Is_same_day = require("date-fns/is_same_day");
var Start_of_day = require("date-fns/start_of_day");
var Start_of_month = require("date-fns/start_of_month");
var Difference_in_days = require("date-fns/difference_in_days");
var Difference_in_calendar_days = require("date-fns/difference_in_calendar_days");

var T = { };

function toString(x) {
  return x.toISOString();
}

function fromString(x) {
  return DateFns.parseString(undefined, x);
}

var toJson = Json_encode.date;

function fromJson(json) {
  return DateFns.parseString(undefined, Json_decode.string(json));
}

var Utc = {
  T: T,
  toString: toString,
  fromString: fromString,
  toJson: toJson,
  fromJson: fromJson
};

var T$1 = { };

function toString$1(x) {
  return DateFns.format("YYYY-MM-DD", x);
}

function fromString$1(x) {
  return DateFns.parseString(undefined, x);
}

function toJson$1(x) {
  return DateFns.format("YYYY-MM-DD", x);
}

function fromJson$1(json) {
  return DateFns.parseString(undefined, Json_decode.string(json));
}

function format(x, t) {
  return DateFns.format(t, x);
}

function make(param) {
  return new Date();
}

function year(x) {
  return x.getFullYear() | 0;
}

function month(x) {
  return x.getMonth() | 0;
}

function date(x) {
  return x.getDate() | 0;
}

function isSameDay(x1, x2) {
  return Is_same_day(x2, x1);
}

function isBefore(x1, x2) {
  return DateFns.isBefore(x2, x1);
}

function isAfter(x1, x2) {
  return DateFns.isAfter(x2, x1);
}

function isPast(x) {
  if (Is_today(x)) {
    return false;
  } else {
    return Is_past(x);
  }
}

function startOfDay(x) {
  return Start_of_day(x);
}

function startOfMonth(x) {
  return Start_of_month(x);
}

function differenceInDays(x1, x2) {
  return Difference_in_days(x1, x2) | 0;
}

function differenceInCalendarDays(x1, x2) {
  return Difference_in_calendar_days(x1, x2) | 0;
}

function addDays(x, n) {
  return DateFns.addDays(n, x);
}

function addMonths(x, n) {
  return DateFns.addMonths(n, x);
}

var Naive = {
  T: T$1,
  toString: toString$1,
  fromString: fromString$1,
  toJson: toJson$1,
  fromJson: fromJson$1,
  format: format,
  make: make,
  year: year,
  month: month,
  date: date,
  isSameDay: isSameDay,
  isBefore: isBefore,
  isAfter: isAfter,
  isPast: isPast,
  startOfDay: startOfDay,
  startOfMonth: startOfMonth,
  differenceInDays: differenceInDays,
  differenceInCalendarDays: differenceInCalendarDays,
  addDays: addDays,
  addMonths: addMonths
};

exports.Utc = Utc;
exports.Naive = Naive;
/* DateFns Not a pure module */
