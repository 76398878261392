// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Opt = require("../libs/Opt.bs.js");
var $$Date = require("../libs/Date.bs.js");
var Host = require("./Host.bs.js");
var Vrbp = require("./Vrbp.bs.js");
var Phone = require("./Phone.bs.js");
var Photo = require("./Photo.bs.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var PropertyType = require("./PropertyType.bs.js");
var CustomHouseRule = require("./CustomHouseRule.bs.js");
var SystemHouseRule = require("./SystemHouseRule.bs.js");
var SharedConstantsJson = require("shared-data/shared-constants.json");
var Difference_in_calendar_days = require("date-fns/difference_in_calendar_days");

var Kind = { };

function toString(param) {
  switch (param) {
    case /* EntireHome */0 :
        return "entire_home";
    case /* PrivateRoom */1 :
        return "private_room";
    case /* SharedRoom */2 :
        return "shared_room";
    
  }
}

function fromString(id) {
  switch (id) {
    case "entire_home" :
        return /* EntireHome */0;
    case "private_room" :
        return /* PrivateRoom */1;
    case "shared_room" :
        return /* SharedRoom */2;
    default:
      return Pervasives.failwith("Unknown room type: " + (String(id) + ""));
  }
}

function fromJson(json) {
  return fromString(Json_decode.string(json));
}

function label(param) {
  switch (param) {
    case /* EntireHome */0 :
        return "Entire home/apt";
    case /* PrivateRoom */1 :
        return "Private room";
    case /* SharedRoom */2 :
        return "Shared room";
    
  }
}

function icon(param) {
  switch (param) {
    case /* EntireHome */0 :
        return "entireHomeIcon";
    case /* PrivateRoom */1 :
        return "privateRoomIcon";
    case /* SharedRoom */2 :
        return "sharedRoomIcon";
    
  }
}

function toRecord(x) {
  return {
          label: label(x),
          id: toString(x),
          iconClassName: icon(x)
        };
}

var RoomType = {
  toString: toString,
  fromString: fromString,
  fromJson: fromJson,
  label: label,
  icon: icon,
  toRecord: toRecord
};

function fromValue(value) {
  var switcher = value - 1 | 0;
  if (switcher > 4 || switcher < 0) {
    return Pervasives.failwith("Unknown listing rating");
  } else {
    return switcher;
  }
}

function toLabel(param) {
  switch (param) {
    case /* BAD */0 :
        return "Bad";
    case /* POOR */1 :
        return "Poor";
    case /* AVERAGE */2 :
        return "Average";
    case /* GOOD */3 :
        return "Good";
    case /* EXCELLENT */4 :
        return "Excellent";
    
  }
}

var Rating = {
  fromValue: fromValue,
  toLabel: toLabel
};

var none = {
  airbnbUrl: undefined,
  vrboUrl: undefined,
  ourQuotedPrice: undefined,
  airbnbQuotedPrice: undefined,
  vrboQuotedPrice: undefined
};

function fromJson$1(json) {
  return {
          airbnbUrl: Json_decode.field("airbnbUrl", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          vrboUrl: Json_decode.field("vrboUrl", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          ourQuotedPrice: Json_decode.field("ourQuotedPrice", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          airbnbQuotedPrice: Json_decode.field("airbnbQuotedPrice", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          vrboQuotedPrice: Json_decode.field("vrboQuotedPrice", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json)
        };
}

function hc(vrbpData) {
  return {
          name: /* HawaiiChee */0,
          price: vrbpData.ourQuotedPrice
        };
}

function airbnb(vrbpData) {
  return {
          name: /* Airbnb */1,
          price: vrbpData.airbnbQuotedPrice
        };
}

function vrbo(vrbpData) {
  return {
          name: /* Vrbo */2,
          price: vrbpData.vrboQuotedPrice
        };
}

function isReady(vrbpData) {
  var match = vrbpData.ourQuotedPrice;
  var match$1 = vrbpData.airbnbQuotedPrice;
  var match$2 = vrbpData.vrboQuotedPrice;
  if (match !== undefined || match$1 !== undefined) {
    return true;
  } else {
    return match$2 !== undefined;
  }
}

var VrbpData = {
  none: none,
  fromJson: fromJson$1,
  hc: hc,
  airbnb: airbnb,
  vrbo: vrbo,
  isReady: isReady
};

function fromId(param) {
  switch (param) {
    case "invitee" :
        return /* Invitee */2;
    case "membership" :
        return /* Member */1;
    case "owner" :
        return /* Owner */3;
    case "public_guest" :
        return /* Guest */4;
    case "requested_membership" :
        return /* RequestedMembership */0;
    default:
      return Pervasives.failwith("Unknown relationship type");
  }
}

var Relationship = {
  fromId: fromId
};

function fromString$1(param) {
  switch (param) {
    case "minimum_stay_30" :
        return /* MinimumStay30 */0;
    case "none" :
        return ;
    default:
      return Pervasives.failwith("Unknown legal restriction");
  }
}

function fromJson$2(json) {
  return fromString$1(Json_decode.string(json));
}

var LegalRestriction = {
  fromString: fromString$1,
  fromJson: fromJson$2
};

function fromJson$3(json) {
  return {
          listingRelationshipType: Belt_Option.map(Json_decode.field("listingRelationshipType", (function (param) {
                      return Json_decode.optional(Json_decode.string, param);
                    }), json), fromId),
          favorited: Belt_Option.getWithDefault(Json_decode.field("favorited", (function (param) {
                      return Json_decode.optional(Json_decode.bool, param);
                    }), json), false),
          isManagedByCurrentUser: Belt_Option.getWithDefault(Json_decode.field("isManagedByCurrentUser", (function (param) {
                      return Json_decode.optional(Json_decode.bool, param);
                    }), json), false)
        };
}

var UserContext = {
  fromJson: fromJson$3
};

function fromJson$4(json) {
  return {
          rating: Json_decode.field("rating", Json_decode.$$float, json),
          count: Json_decode.field("count", Json_decode.$$int, json)
        };
}

function fromJs(js) {
  return {
          rating: js.rating,
          count: js.count
        };
}

var RatingData = {
  fromJson: fromJson$4,
  fromJs: fromJs
};

function fromJson$5(json) {
  return {
          hawaiichee: Json_decode.field("hawaiichee", fromJson$4, json),
          metaRating: Json_decode.field("metaRating", fromJson$4, json),
          vrbo: Json_decode.optional((function (__x) {
                  return Json_decode.field("vrbo", fromJson$4, __x);
                }), json),
          airbnb: Json_decode.optional((function (__x) {
                  return Json_decode.field("airbnb", fromJson$4, __x);
                }), json)
        };
}

function fromJs$1(js) {
  return {
          hawaiichee: fromJs(js.hawaiichee),
          metaRating: fromJs(js.metaRating),
          vrbo: Belt_Option.map(Caml_option.nullable_to_opt(js.vrbo), fromJs),
          airbnb: Belt_Option.map(Caml_option.nullable_to_opt(js.airbnb), fromJs)
        };
}

var ProvidersRatings = {
  fromJson: fromJson$5,
  fromJs: fromJs$1
};

var ProviderUrl = { };

function price(pricePerNight, checkIn, checkOut) {
  var nights = $$Date.Naive.differenceInDays(checkOut, checkIn);
  return {
          total: pricePerNight * nights,
          perNight: pricePerNight
        };
}

function make(url, pricePerNight, checkIn, checkOut) {
  return Belt_Option.map(url, (function (url) {
                return {
                        url: url,
                        price: pricePerNight !== undefined && checkIn !== undefined && checkOut !== undefined ? price(pricePerNight, Caml_option.valFromOption(checkIn), Caml_option.valFromOption(checkOut)) : undefined
                      };
              }));
}

var ProviderData = {
  price: price,
  make: make
};

function decodeSampleQuoteGuests(json) {
  return {
          adults: Json_decode.field("adults", Json_decode.$$int, json),
          children: Json_decode.field("children", Json_decode.$$int, json),
          infants: Json_decode.field("infants", Json_decode.$$int, json),
          pets: Json_decode.field("pets", Json_decode.bool, json)
        };
}

var decodeSharedConstants = {
  sampleQuoteGuests: Json_decode.field("sampleQuoteGuests", decodeSampleQuoteGuests, SharedConstantsJson)
};

var sampleGuests = decodeSharedConstants.sampleQuoteGuests;

function fromJson$6(json) {
  var vrbpDataField = function (json, key, decoder) {
    return Belt_Option.flatMap(Json_decode.field("vrbpData", (function (param) {
                      return Json_decode.optional((function (param) {
                                    return Json_decode.field(key, decoder, param);
                                  }), param);
                    }), json), (function (x) {
                  return x;
                }));
  };
  var sampleCheckIn = Json_decode.field("quotedPricesCheckIn", (function (param) {
          return Json_decode.optional($$Date.Naive.fromJson, param);
        }), json);
  var sampleCheckOut = Json_decode.field("quotedPricesCheckOut", (function (param) {
          return Json_decode.optional($$Date.Naive.fromJson, param);
        }), json);
  var airbnbUrl = vrbpDataField(json, "airbnbUrl", (function (param) {
          return Json_decode.optional(Json_decode.string, param);
        }));
  var vrboUrl = vrbpDataField(json, "vrboUrl", (function (param) {
          return Json_decode.optional(Json_decode.string, param);
        }));
  var hcPricePerNight = vrbpDataField(json, "ourQuotedPrice", (function (param) {
          return Json_decode.optional(Json_decode.$$float, param);
        }));
  var airbnbPricePerNight = vrbpDataField(json, "airbnbQuotedPrice", (function (param) {
          return Json_decode.optional(Json_decode.$$float, param);
        }));
  var vrboPricePerNight = vrbpDataField(json, "vrboQuotedPrice", (function (param) {
          return Json_decode.optional(Json_decode.$$float, param);
        }));
  var hc = make(Caml_option.some(undefined), hcPricePerNight, sampleCheckIn, sampleCheckOut);
  var airbnb = make(Belt_Option.map(airbnbUrl, (function (prim) {
              return prim;
            })), airbnbPricePerNight, sampleCheckIn, sampleCheckOut);
  var vrbo = make(Belt_Option.map(vrboUrl, (function (prim) {
              return prim;
            })), vrboPricePerNight, sampleCheckIn, sampleCheckOut);
  var sample = function (provider, data) {
    return Belt_Option.map(Belt_Option.flatMap(data, (function (data) {
                      return data.price;
                    })), (function (price) {
                  return {
                          provider: provider,
                          total: price.total
                        };
                }));
  };
  var sampleData = Belt_Array.reduce([
        sample(/* HawaiiChee */0, hc),
        sample(/* Airbnb */1, airbnb),
        sample(/* Vrbo */2, vrbo)
      ], [], (function (acc, data) {
          if (data !== undefined) {
            return Belt_Array.concat(acc, [data]);
          } else {
            return acc;
          }
        }));
  var bestPrice = Vrbp.BestPrice.fromSampleData(sampleData);
  return {
          sampleCheckIn: sampleCheckIn,
          sampleCheckOut: sampleCheckOut,
          airbnb: airbnb,
          vrbo: vrbo,
          hc: hc,
          bestPrice: bestPrice
        };
}

var VrbpData$1 = {
  decodeSampleQuoteGuests: decodeSampleQuoteGuests,
  decodeSharedConstants: decodeSharedConstants,
  sampleGuests: sampleGuests,
  fromJson: fromJson$6
};

function fromJson$7(json) {
  var vrbpData = fromJson$6(json);
  var match = Json_decode.field("providersRatings", fromJson$5, json);
  var airbnbRating = match.airbnb;
  var vrboRating = match.vrbo;
  var airbnbRating$1 = airbnbRating !== undefined && vrbpData.airbnb !== undefined ? airbnbRating : undefined;
  var vrboRating$1 = vrboRating !== undefined && vrbpData.vrbo !== undefined ? vrboRating : undefined;
  var providersRatings_hawaiichee = match.hawaiichee;
  var providersRatings_metaRating = match.metaRating;
  var providersRatings = {
    hawaiichee: providersRatings_hawaiichee,
    metaRating: providersRatings_metaRating,
    vrbo: vrboRating$1,
    airbnb: airbnbRating$1
  };
  var match$1 = Json_decode.field("isMetalisting", Json_decode.bool, json);
  var match$2 = Json_decode.field("claimed", Json_decode.bool, json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          title: Json_decode.field("title", Json_decode.string, json),
          summary: Belt_Option.flatMap(Json_decode.field("summary", (function (param) {
                      return Json_decode.optional(Json_decode.string, param);
                    }), json), Opt.fromString),
          description: Belt_Option.flatMap(Json_decode.field("description", (function (param) {
                      return Json_decode.optional(Json_decode.string, param);
                    }), json), Opt.fromString),
          photos: Opt.fromArray(Json_decode.field("photos", (function (param) {
                      return Json_decode.array(Photo.fromJson, param);
                    }), json)),
          urls: Json_decode.field("urls", (function (param) {
                  return Json_decode.array(Json_decode.string, param);
                }), json),
          city: Json_decode.field("city", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          state: Json_decode.field("state", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          lat: Json_decode.field("lat", Json_decode.$$float, json),
          lng: Json_decode.field("lng", Json_decode.$$float, json),
          showApproximateLocation: Json_decode.field("showApproximateLocation", Json_decode.bool, json),
          bathrooms: Json_decode.field("bathrooms", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          bedrooms: Json_decode.field("bedrooms", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          beds: Json_decode.field("beds", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          amenityIds: Opt.fromArray(Json_decode.field("amenityIds", (function (param) {
                      return Json_decode.array(Json_decode.$$int, param);
                    }), json)),
          sameDayCheckInAndOut: Json_decode.field("sameDayCheckInAndOut", Json_decode.bool, json),
          minimumStay: Json_decode.field("minimumStay", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          checkInHour: Json_decode.field("checkInHour", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          checkOutHour: Json_decode.field("checkOutHour", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          maxGuests: Json_decode.field("maxGuests", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          roomType: Json_decode.field("roomType", fromJson, json),
          propertyType: Json_decode.field("propertyType", (function (param) {
                  return Json_decode.optional(PropertyType.fromJson, param);
                }), json),
          samplePrice: Opt.fromFloat(Json_decode.field("samplePrice", Json_decode.$$float, json)),
          weeklyDiscount: Opt.mapFromFloat(Json_decode.field("weeklyDiscount", Json_decode.$$float, json), (function (x) {
                  return x / 100;
                })),
          monthlyDiscount: Opt.mapFromFloat(Json_decode.field("monthlyDiscount", Json_decode.$$float, json), (function (x) {
                  return x / 100;
                })),
          deposit: Belt_Option.flatMap(Json_decode.field("deposit", (function (param) {
                      return Json_decode.optional(Json_decode.$$float, param);
                    }), json), Opt.fromFloat),
          cleaningFee: Belt_Option.flatMap(Json_decode.field("cleaningFee", (function (param) {
                      return Json_decode.optional(Json_decode.$$float, param);
                    }), json), Opt.fromFloat),
          owner: Json_decode.field("owner", Host.fromJson, json),
          kind: match$1 ? (
              match$2 ? /* MetaListing */[/* Claimed */809617083] : /* MetaListing */[/* Unclaimed */12944770]
            ) : /* GeneralListing */0,
          vrbpData: vrbpData,
          legalRestriction: Json_decode.field("legalRestriction", fromJson$2, json),
          currentDate: Json_decode.field("currentDate", $$Date.Naive.fromJson, json),
          systemHouseRules: Json_decode.field("systemHouseRules", SystemHouseRule.All.fromJson, json),
          customHouseRules: Json_decode.field("customHouseRules", (function (param) {
                  return Json_decode.array(CustomHouseRule.fromJson, param);
                }), json),
          providersRatings: providersRatings,
          contactPhone: Json_decode.optional((function (__x) {
                  return Json_decode.field("contactPhone", Phone.fromJson, __x);
                }), json),
          rentalPermitLicense: Json_decode.field("rentalPermitLicense", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          userContext: Json_decode.field("userContext", fromJson$3, json)
        };
}

var Contextual = {
  ProviderUrl: ProviderUrl,
  ProviderData: ProviderData,
  VrbpData: VrbpData$1,
  fromJson: fromJson$7
};

function fromJson$8(json) {
  var match = Json_decode.field("isMetalisting", Json_decode.bool, json);
  var match$1 = Json_decode.field("claimed", Json_decode.bool, json);
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          title: Json_decode.field("title", Json_decode.string, json),
          htmlUrl: Json_decode.field("htmlUrl", Json_decode.string, json),
          roomType: Json_decode.field("roomType", fromJson, json),
          bedrooms: Json_decode.field("bedrooms", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          bathrooms: Json_decode.field("bathrooms", (function (param) {
                  return Json_decode.optional(Json_decode.$$float, param);
                }), json),
          maxGuests: Json_decode.field("maxGuests", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          amenityIds: Opt.fromArray(Json_decode.field("amenityIds", (function (param) {
                      return Json_decode.array(Json_decode.$$int, param);
                    }), json)),
          zipCode: Json_decode.field("zipCode", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          country: Json_decode.field("country", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          city: Json_decode.field("city", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          state: Json_decode.field("state", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          street: Json_decode.field("street", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          apt: Json_decode.field("apt", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          photos: Json_decode.field("photos", (function (param) {
                  return Json_decode.array(Photo.fromJson, param);
                }), json),
          favoritesCount: Json_decode.field("favoritesCount", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          userContext: Json_decode.field("userContext", fromJson$3, json),
          legalRestriction: Json_decode.field("legalRestriction", fromJson$2, json),
          vrbpData: Belt_Option.getWithDefault(Json_decode.field("vrbpData", (function (param) {
                      return Json_decode.optional(fromJson$1, param);
                    }), json), none),
          quotedPricesCheckIn: Json_decode.field("quotedPricesCheckIn", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          quotedPricesCheckOut: Json_decode.field("quotedPricesCheckOut", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          providersRatings: Json_decode.field("providersRatings", (function (param) {
                  return Json_decode.optional(fromJson$5, param);
                }), json),
          showApproximateLocation: Json_decode.field("showApproximateLocation", Json_decode.bool, json),
          kind: match ? (
              match$1 ? /* MetaListing */[/* Claimed */809617083] : /* MetaListing */[/* Unclaimed */12944770]
            ) : /* GeneralListing */0
        };
}

function fromFullVrbpData(vrbpData) {
  return {
          hc: vrbpData.ourQuotedPrice,
          vrbo: vrbpData.vrboQuotedPrice,
          airbnb: vrbpData.airbnbQuotedPrice
        };
}

function getTotalPriceForProvider(price, numberOfNights) {
  if (price !== undefined) {
    return price * numberOfNights;
  }
  
}

function toTotalPrices(fullPrices, numberOfNights) {
  return {
          hc: getTotalPriceForProvider(fullPrices.hc, numberOfNights),
          vrbo: getTotalPriceForProvider(fullPrices.vrbo, numberOfNights),
          airbnb: getTotalPriceForProvider(fullPrices.airbnb, numberOfNights)
        };
}

function getProvidersAvailability(fullPrices) {
  return {
          hc: fullPrices.hc !== undefined,
          vrbo: fullPrices.vrbo !== undefined,
          airbnb: fullPrices.airbnb !== undefined
        };
}

var FullPricesState = {
  fromFullVrbpData: fromFullVrbpData,
  getTotalPriceForProvider: getTotalPriceForProvider,
  toTotalPrices: toTotalPrices,
  getProvidersAvailability: getProvidersAvailability
};

function providerFromJs(provider) {
  if (provider !== undefined) {
    return Vrbp.Quote.fromJs(Caml_option.valFromOption(provider));
  }
  
}

function fromJs$2(js) {
  if (js == null) {
    return ;
  }
  var hcPrices = providerFromJs(Caml_option.nullable_to_opt(js.hc));
  var vrboPrices = providerFromJs(Caml_option.nullable_to_opt(js.vrbo));
  var airbnbPrices = providerFromJs(Caml_option.nullable_to_opt(js.airbnb));
  if (hcPrices === undefined && vrboPrices === undefined && airbnbPrices === undefined) {
    return ;
  }
  return {
          hc: hcPrices,
          vrbo: vrboPrices,
          airbnb: airbnbPrices
        };
}

function getTotalPriceForProvider$1(livePricesDataForProvider) {
  if (livePricesDataForProvider !== undefined) {
    return livePricesDataForProvider.total;
  }
  
}

function toTotalPrices$1(livePrices) {
  return {
          hc: getTotalPriceForProvider$1(livePrices.hc),
          vrbo: getTotalPriceForProvider$1(livePrices.vrbo),
          airbnb: getTotalPriceForProvider$1(livePrices.airbnb)
        };
}

function fromString$2(param) {
  switch (param) {
    case "failed" :
        return /* Failed */3;
    case "not_started" :
        return /* NotStarted */0;
    case "pending" :
        return /* Pending */1;
    case "ready" :
        return /* Ready */2;
    default:
      return Pervasives.failwith("Unknown live prices fetching state");
  }
}

function fromJson$9(json) {
  return fromString$2(Json_decode.string(json));
}

var FetchingState = {
  fromString: fromString$2,
  fromJson: fromJson$9
};

var LivePricesState_emptyPrices = {
  hc: undefined,
  vrbo: undefined,
  airbnb: undefined
};

var LivePricesState = {
  providerFromJs: providerFromJs,
  fromJs: fromJs$2,
  getTotalPriceForProvider: getTotalPriceForProvider$1,
  toTotalPrices: toTotalPrices$1,
  emptyPrices: LivePricesState_emptyPrices,
  FetchingState: FetchingState
};

function totalPricesFromState(fullPricesState, livePricesState, numberOfNights) {
  if (livePricesState === undefined) {
    return {
            hc: getTotalPriceForProvider(fullPricesState.hc, numberOfNights),
            vrbo: getTotalPriceForProvider(fullPricesState.vrbo, numberOfNights),
            airbnb: getTotalPriceForProvider(fullPricesState.airbnb, numberOfNights)
          };
  }
  var price = getTotalPriceForProvider$1(livePricesState.hc);
  var price$1 = getTotalPriceForProvider$1(livePricesState.vrbo);
  var price$2 = getTotalPriceForProvider$1(livePricesState.airbnb);
  return {
          hc: price !== undefined ? price : getTotalPriceForProvider(fullPricesState.hc, numberOfNights),
          vrbo: price$1 !== undefined ? price$1 : getTotalPriceForProvider(fullPricesState.vrbo, numberOfNights),
          airbnb: price$2 !== undefined ? price$2 : getTotalPriceForProvider(fullPricesState.airbnb, numberOfNights)
        };
}

function getNumberOfNights(datesPeriod) {
  if (datesPeriod !== undefined) {
    return Difference_in_calendar_days(datesPeriod.values[1], datesPeriod.values[0]);
  } else {
    return 1;
  }
}

var DatesPeriod = {
  getNumberOfNights: getNumberOfNights
};

function fromString$3(param) {
  switch (param) {
    case "blv" :
        return /* Blv */1;
    case "homepage" :
        return /* Homepage */0;
    case "map" :
        return /* Map */2;
    default:
      return Pervasives.failwith("Unknown listing tile style");
  }
}

function toString$1(param) {
  switch (param) {
    case /* Homepage */0 :
        return "homepage";
    case /* Blv */1 :
        return "blv";
    case /* Map */2 :
        return "map";
    
  }
}

function fromJson$10(json) {
  return fromString$3(Json_decode.string(json));
}

var Style = {
  fromString: fromString$3,
  toString: toString$1,
  fromJson: fromJson$10
};

var TitleClickAction = { };

function fromJs$3(param) {
  switch (param) {
    case "danger" :
        return /* Danger */0;
    case "dark" :
        return /* Dark */2;
    case "success" :
        return /* Success */1;
    default:
      return Pervasives.failwith("Unknown tile status type");
  }
}

var StatusColor = {
  fromJs: fromJs$3
};

var Tile = {
  fromJson: fromJson$8,
  FullPricesState: FullPricesState,
  LivePricesState: LivePricesState,
  totalPricesFromState: totalPricesFromState,
  DatesPeriod: DatesPeriod,
  Style: Style,
  TitleClickAction: TitleClickAction,
  StatusColor: StatusColor
};

exports.Kind = Kind;
exports.RoomType = RoomType;
exports.Rating = Rating;
exports.VrbpData = VrbpData;
exports.Relationship = Relationship;
exports.LegalRestriction = LegalRestriction;
exports.UserContext = UserContext;
exports.RatingData = RatingData;
exports.ProvidersRatings = ProvidersRatings;
exports.Contextual = Contextual;
exports.Tile = Tile;
/* decodeSharedConstants Not a pure module */
