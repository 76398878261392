// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var white = "#ffffff";

var teal = "#03cccb";

var lightTeal = "#ebf9f9";

var silver = "#eff5f7";

var orange = "#f5764f";

var red = "#f9565b";

var magenta = "#ff0153";

var yellow = "#ffbd42";

var darkBlue = "#506477";

var darkGray = "#222";

var gray = "#555";

var lightGray = "#777";

var lighterGray = "#b8b8b8";

var lightestGray = "#e8e8e8";

var extraLightGray = "#f5f5f5";

exports.white = white;
exports.teal = teal;
exports.lightTeal = lightTeal;
exports.silver = silver;
exports.orange = orange;
exports.red = red;
exports.magenta = magenta;
exports.yellow = yellow;
exports.darkBlue = darkBlue;
exports.darkGray = darkGray;
exports.gray = gray;
exports.lightGray = lightGray;
exports.lighterGray = lighterGray;
exports.lightestGray = lightestGray;
exports.extraLightGray = extraLightGray;
/* No side effect */
