// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function VrsGallery(Props) {
  return React.createElement("div", {
              "aria-hidden": true,
              className: "pswp",
              role: "dialog",
              tabIndex: -1
            }, React.createElement("div", {
                  className: "pswp__bg"
                }), React.createElement("div", {
                  className: "pswp__scroll-wrap"
                }, React.createElement("div", {
                      className: "pswp__container"
                    }, React.createElement("div", {
                          className: "pswp__item"
                        }), React.createElement("div", {
                          className: "pswp__item"
                        }), React.createElement("div", {
                          className: "pswp__item"
                        })), React.createElement("div", {
                      className: "pswp__ui pswp__ui--hidden"
                    }, React.createElement("div", {
                          className: "pswp__top-bar"
                        }, React.createElement("div", {
                              className: "pswp__counter"
                            }), React.createElement("button", {
                              className: "pswp__button pswp__button--close",
                              title: "Close (Esc)"
                            }), React.createElement("button", {
                              className: "pswp__button pswp__button--share",
                              title: "Share"
                            }), React.createElement("button", {
                              className: "pswp__button pswp__button--fs",
                              title: "Toggle fullscreen"
                            }), React.createElement("button", {
                              className: "pswp__button pswp__button--download-original",
                              title: "Download original"
                            }), React.createElement("button", {
                              className: "pswp__button pswp__button--zoom",
                              title: "Zoom in/out"
                            }), React.createElement("div", {
                              className: "pswp__preloader"
                            }, React.createElement("div", {
                                  className: "pswp__preloader__icn"
                                }, React.createElement("div", {
                                      className: "pswp__preloader__cut"
                                    }, React.createElement("div", {
                                          className: "pswp__preloader__donut"
                                        }))))), React.createElement("div", {
                          className: "pswp__share-modal pswp__share-modal--hidden pswp__single-tap"
                        }, React.createElement("div", {
                              className: "pswp__share-tooltip"
                            })), React.createElement("button", {
                          className: "pswp__button pswp__button--arrow--left",
                          title: "Previous (arrow left)"
                        }), React.createElement("button", {
                          className: "pswp__button pswp__button--arrow--right",
                          title: "Next (arrow right)"
                        }), React.createElement("div", {
                          className: "pswp__caption"
                        }, React.createElement("div", {
                              className: "pswp__caption__center"
                            })))));
}

var make = VrsGallery;

exports.make = make;
/* react Not a pure module */
