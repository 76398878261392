// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Btn = require("./Btn.bs.js");
var React = require("react");
var WithTestId = require("../WithTestId/WithTestId.bs.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ButtonModuleScss = require("./Button.module.scss");

var css = ButtonModuleScss;

function RegularButtonActionless(Props) {
  var size = Props.size;
  var color = Props.color;
  var margin = Props.margin;
  var ghostOpt = Props.ghost;
  var expandedOpt = Props.expanded;
  var visuallyDisabledOpt = Props.visuallyDisabled;
  var classNameOpt = Props.className;
  var testId = Props.testId;
  var ariaLabel = Props.ariaLabel;
  var onClick = Props.onClick;
  var children = Props.children;
  var ghost = ghostOpt !== undefined ? ghostOpt : false;
  var expanded = expandedOpt !== undefined ? expandedOpt : false;
  var visuallyDisabled = visuallyDisabledOpt !== undefined ? visuallyDisabledOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var tmp = {
    className: Btn.className(size, color, ghost, expanded, margin, visuallyDisabled, false, className)
  };
  if (ariaLabel !== undefined) {
    tmp["aria-label"] = Caml_option.valFromOption(ariaLabel);
  }
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return React.createElement(WithTestId.Optional.make, {
              id: testId,
              children: React.createElement("span", tmp, children)
            });
}

var make = RegularButtonActionless;

exports.css = css;
exports.make = make;
/* css Not a pure module */
