// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_math = require("bs-platform/lib/js/js_math.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/src/Json_decode.bs.js");
var Photos = require("app/libs/utils/fng/photos");

function fromJs(js) {
  return {
          id: js.id,
          listingId: js.listingId,
          cloudUrl: js.cloudUrl,
          format: Caml_option.nullable_to_opt(js.format),
          description: Caml_option.nullable_to_opt(js.description),
          width: Caml_option.nullable_to_opt(js.width),
          height: Caml_option.nullable_to_opt(js.height),
          position: js.position,
          publicId: Caml_option.nullable_to_opt(js.publicId)
        };
}

function fromJson(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          listingId: Json_decode.field("listingId", Json_decode.$$int, json),
          cloudUrl: Json_decode.field("cloudUrl", Json_decode.string, json),
          format: Json_decode.field("format", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          description: Json_decode.field("description", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json),
          width: Json_decode.field("width", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          height: Json_decode.field("height", (function (param) {
                  return Json_decode.optional(Json_decode.$$int, param);
                }), json),
          position: Json_decode.field("position", Json_decode.$$int, json),
          publicId: Json_decode.field("publicId", (function (param) {
                  return Json_decode.optional(Json_decode.string, param);
                }), json)
        };
}

function url(src, width, height) {
  return Photos.buildPhotoUrl({
              url: src,
              width: width,
              height: height,
              format: "jpg",
              crop: "fill"
            });
}

function dimensions(width, height) {
  if (width <= 2500) {
    return /* tuple */[
            width,
            height
          ];
  } else {
    return /* tuple */[
            2500,
            Js_math.floor(height * (2500 / width))
          ];
  }
}

var maxWidth = 2500;

exports.maxWidth = maxWidth;
exports.fromJs = fromJs;
exports.fromJson = fromJson;
exports.url = url;
exports.dimensions = dimensions;
/* app/libs/utils/fng/photos Not a pure module */
