// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var I18n = require("../../../libs/I18n.bs.js");
var React = require("react");
var ReactIntl = require("react-intl");

function Currency$WithoutCents(Props) {
  var children = Props.children;
  return React.createElement(ReactIntl.FormattedNumber, {
              value: children,
              style: "currency",
              currency: I18n.currency,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            });
}

var WithoutCents = {
  make: Currency$WithoutCents
};

exports.WithoutCents = WithoutCents;
/* react Not a pure module */
