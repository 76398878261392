// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var maxWidth = String(767);

var smMq = "only screen and (max-width: " + (String(maxWidth) + "px)");

var minWidth = String(768);

var maxWidth$1 = String(1079);

var mdMq = "only screen and (min-width: " + (String(minWidth) + ("px) and (max-width: " + (String(maxWidth$1) + "px)")));

var minWidth$1 = String(1080);

var lgMq = "only screen and (min-width: " + (String(minWidth$1) + "px)");

var sm = 320;

var md = 768;

var lg = 1080;

exports.sm = sm;
exports.md = md;
exports.lg = lg;
exports.smMq = smMq;
exports.mdMq = mdMq;
exports.lgMq = lgMq;
/* maxWidth Not a pure module */
