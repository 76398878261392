// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Cn = require("re-classnames/src/Cn.bs.js");
var Svg = require("../Svg/Svg.bs.js");
var React = require("react");
var Colors = require("../../styles/Colors.bs.js");
var IconModuleScss = require("./Icon.module.scss");

var css = IconModuleScss;

var iconSize = "16";

var Margin = { };

function mapColor(color) {
  switch (color) {
    case /* White */0 :
        return Colors.white;
    case /* Teal */1 :
        return Colors.teal;
    case /* LightTeal */2 :
        return Colors.lightTeal;
    case /* Yellow */3 :
        return Colors.yellow;
    case /* Magenta */4 :
        return Colors.magenta;
    case /* Red */5 :
        return Colors.red;
    case /* DarkBlue */6 :
        return Colors.darkBlue;
    case /* DarkGray */7 :
        return Colors.darkGray;
    case /* Gray */8 :
        return Colors.gray;
    case /* LightGray */9 :
        return Colors.lightGray;
    case /* LighterGray */10 :
        return Colors.lighterGray;
    case /* LightestGray */11 :
        return Colors.lightestGray;
    case /* ExtraLightGray */12 :
        return Colors.extraLightGray;
    
  }
}

function style(color) {
  return {
          fill: mapColor(color)
        };
}

function iconClassName(size, margin, className, param) {
  var tmp;
  switch (size) {
    case /* XS */0 :
        tmp = css.sizeXs;
        break;
    case /* SM */1 :
        tmp = css.sizeSm;
        break;
    case /* MD */2 :
        tmp = css.sizeMd;
        break;
    case /* LG */3 :
        tmp = css.sizeLg;
        break;
    case /* XL */4 :
        tmp = css.sizeXl;
        break;
    
  }
  return Cn.$plus(Cn.$plus(Cn.$plus(css.icon, tmp), margin !== undefined ? (
                    margin ? css.marginRight : css.marginLeft
                  ) : ""), className);
}

function Icon(Props) {
  var title = Props.title;
  var size = Props.size;
  var margin = Props.margin;
  var classNameOpt = Props.className;
  var children = Props.children;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Svg.make, {
              title: title,
              viewBoxWidth: iconSize,
              viewBoxHeight: iconSize,
              className: iconClassName(size, margin, className, undefined),
              children: children
            });
}

var make = Icon;

exports.css = css;
exports.iconSize = iconSize;
exports.Margin = Margin;
exports.mapColor = mapColor;
exports.style = style;
exports.iconClassName = iconClassName;
exports.make = make;
/* css Not a pure module */
