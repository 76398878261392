// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var $$Screen = require("../styleguide/styles/Screen.bs.js");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");

function toString(param) {
  switch (param) {
    case /* Png */1 :
        return "png";
    case /* Jpg */0 :
    case /* Auto */2 :
        return "jpg";
    
  }
}

function toParam(param) {
  switch (param) {
    case /* Jpg */0 :
        return "jpg";
    case /* Png */1 :
        return "png";
    case /* Auto */2 :
        return "auto";
    
  }
}

var Format = {
  toString: toString,
  toParam: toParam
};

function toParam$1(param) {
  switch (param) {
    case /* Fit */0 :
        return "fit";
    case /* MinFit */1 :
        return "mfit";
    case /* Fill */2 :
        return "fill";
    case /* LimitFill */3 :
        return "lfill";
    case /* Pad */4 :
        return "pad";
    case /* FillPad */5 :
        return "fill_pad";
    case /* MinPad */6 :
        return "mpad";
    case /* LimitPad */7 :
        return "lpad";
    case /* Crop */8 :
        return "crop";
    case /* Limit */9 :
        return "limit";
    case /* Thumb */10 :
        return "thumb";
    case /* Scale */11 :
        return "scale";
    
  }
}

function fromJs(param) {
  switch (param) {
    case "crop" :
        return /* Crop */8;
    case "fill" :
        return /* Fill */2;
    case "fill_pad" :
        return /* FillPad */5;
    case "fit" :
        return /* Fit */0;
    case "lfill" :
        return /* LimitFill */3;
    case "limit" :
        return /* Limit */9;
    case "lpad" :
        return /* LimitPad */7;
    case "mfit" :
        return /* MinFit */1;
    case "mpad" :
        return /* MinPad */6;
    case "pad" :
        return /* Pad */4;
    case "scale" :
        return /* Scale */11;
    case "thumb" :
        return /* Thumb */10;
    default:
      return Pervasives.failwith("Unknown transformation crop type");
  }
}

var Crop = {
  toParam: toParam$1,
  fromJs: fromJs
};

function toUrlParam(w) {
  if (typeof w === "number") {
    if (w === /* Quality */0) {
      return "q_auto";
    } else {
      return "dpr_auto";
    }
  }
  switch (w.tag | 0) {
    case /* Width */0 :
        return "w_" + String(w[0]);
    case /* Height */1 :
        return "h_" + String(w[0]);
    case /* FetchFormat */2 :
        return "f_" + toParam(w[0]);
    case /* Crop */3 :
        return "c_" + toParam$1(w[0]);
    
  }
}

function build(transformations) {
  return $$String.concat(",", Belt_List.map(transformations, toUrlParam));
}

function inject(transformations, url) {
  return url.replace("upload", "upload/" + transformations).replace("fetch", "fetch/" + transformations).replace("facebook", "facebook/" + transformations);
}

var Transformations = {
  Format: Format,
  Crop: Crop,
  toUrlParam: toUrlParam,
  build: build,
  inject: inject
};

function hasExt(url) {
  var extensionLength = url.substr(url.lastIndexOf(".") + 1 | 0, url.length).length;
  return extensionLength <= 4;
}

function setFormat(format, url) {
  var match = hasExt(url);
  if (format !== undefined) {
    if (match) {
      return url.substr(0, url.lastIndexOf(".")).concat(".", toString(format));
    } else {
      return url + ("." + toString(format));
    }
  } else {
    return url;
  }
}

function srcSetItem(url, width) {
  return url + (" " + (String(width) + "w"));
}

function srcSet(sets) {
  return $$String.concat(", ", sets);
}

function sizesItem(width, lastOpt, breakpoint) {
  var last = lastOpt !== undefined ? lastOpt : false;
  var widthPx = String(width) + "px";
  var breakpointPx = String(breakpoint) + "px";
  if (last) {
    return widthPx;
  } else {
    return "(max-width: " + (breakpointPx + (") " + widthPx));
  }
}

function sizes(sizes$1) {
  return $$String.concat(", ", sizes$1);
}

function build$1(format, transformations, url) {
  return setFormat(format, inject(build(transformations), url));
}

function src(desktop, tablet, mobile, format, transformations, url) {
  var desktopWidth = desktop[0];
  var desktopHeight = desktop[1];
  var desktopTransformations_000 = /* Width */Block.__(0, [desktopWidth]);
  var desktopTransformations_001 = /* :: */[
    /* Height */Block.__(1, [desktopHeight]),
    transformations
  ];
  var desktopTransformations = /* :: */[
    desktopTransformations_000,
    desktopTransformations_001
  ];
  var desktopUrl = build$1(format, desktopTransformations, url);
  if (tablet !== undefined) {
    if (mobile !== undefined) {
      var tabletWidth = tablet[0];
      var tabletHeight = tablet[1];
      var tabletTransformations_000 = /* Width */Block.__(0, [tabletWidth]);
      var tabletTransformations_001 = /* :: */[
        /* Height */Block.__(1, [tabletHeight]),
        transformations
      ];
      var tabletTransformations = /* :: */[
        tabletTransformations_000,
        tabletTransformations_001
      ];
      var tabletUrl = build$1(format, tabletTransformations, url);
      var mobileWidth = mobile[0];
      var mobileHeight = mobile[1];
      var mobileTransformations_000 = /* Width */Block.__(0, [mobileWidth]);
      var mobileTransformations_001 = /* :: */[
        /* Height */Block.__(1, [mobileHeight]),
        transformations
      ];
      var mobileTransformations = /* :: */[
        mobileTransformations_000,
        mobileTransformations_001
      ];
      var mobileUrl = build$1(format, mobileTransformations, url);
      var srcSet = $$String.concat(", ", /* :: */[
            srcSetItem(mobileUrl, mobileWidth),
            /* :: */[
              srcSetItem(tabletUrl, tabletWidth),
              /* :: */[
                srcSetItem(desktopUrl, desktopWidth),
                /* [] */0
              ]
            ]
          ]);
      var sizes = $$String.concat(", ", /* :: */[
            sizesItem(mobileWidth, undefined, $$Screen.sm),
            /* :: */[
              sizesItem(tabletWidth, undefined, $$Screen.md),
              /* :: */[
                sizesItem(desktopWidth, true, $$Screen.lg),
                /* [] */0
              ]
            ]
          ]);
      return /* tuple */[
              desktopUrl,
              srcSet,
              sizes
            ];
    }
    var tabletWidth$1 = tablet[0];
    var tabletHeight$1 = tablet[1];
    var tabletTransformations_000$1 = /* Width */Block.__(0, [tabletWidth$1]);
    var tabletTransformations_001$1 = /* :: */[
      /* Height */Block.__(1, [tabletHeight$1]),
      transformations
    ];
    var tabletTransformations$1 = /* :: */[
      tabletTransformations_000$1,
      tabletTransformations_001$1
    ];
    var tabletUrl$1 = build$1(format, tabletTransformations$1, url);
    var srcSet$1 = $$String.concat(", ", /* :: */[
          srcSetItem(tabletUrl$1, tabletWidth$1),
          /* :: */[
            srcSetItem(desktopUrl, desktopWidth),
            /* [] */0
          ]
        ]);
    var sizes$1 = $$String.concat(", ", /* :: */[
          sizesItem(tabletWidth$1, undefined, $$Screen.md),
          /* :: */[
            sizesItem(desktopWidth, true, $$Screen.lg),
            /* [] */0
          ]
        ]);
    return /* tuple */[
            desktopUrl,
            srcSet$1,
            sizes$1
          ];
  }
  if (mobile === undefined) {
    return /* tuple */[
            desktopUrl,
            undefined,
            undefined
          ];
  }
  var mobileWidth$1 = mobile[0];
  var mobileHeight$1 = mobile[1];
  var mobileTransformations_000$1 = /* Width */Block.__(0, [mobileWidth$1]);
  var mobileTransformations_001$1 = /* :: */[
    /* Height */Block.__(1, [mobileHeight$1]),
    transformations
  ];
  var mobileTransformations$1 = /* :: */[
    mobileTransformations_000$1,
    mobileTransformations_001$1
  ];
  var mobileUrl$1 = build$1(format, mobileTransformations$1, url);
  var srcSet$2 = $$String.concat(", ", /* :: */[
        srcSetItem(mobileUrl$1, mobileWidth$1),
        /* :: */[
          srcSetItem(desktopUrl, desktopWidth),
          /* [] */0
        ]
      ]);
  var sizes$2 = $$String.concat(", ", /* :: */[
        sizesItem(mobileWidth$1, undefined, $$Screen.sm),
        /* :: */[
          sizesItem(desktopWidth, true, $$Screen.lg),
          /* [] */0
        ]
      ]);
  return /* tuple */[
          desktopUrl,
          srcSet$2,
          sizes$2
        ];
}

var Url = {
  hasExt: hasExt,
  setFormat: setFormat,
  srcSetItem: srcSetItem,
  srcSet: srcSet,
  sizesItem: sizesItem,
  sizes: sizes,
  build: build$1,
  src: src
};

exports.Transformations = Transformations;
exports.Url = Url;
/* Screen Not a pure module */
