// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");

function toString(x) {
  if (x !== undefined) {
    return x;
  } else {
    return "";
  }
}

function fromString(x) {
  if (x === "") {
    return ;
  } else {
    return x;
  }
}

function toArray(x) {
  if (x !== undefined) {
    return x;
  } else {
    return [];
  }
}

function fromArray(x) {
  if (x.length !== 0) {
    return x;
  }
  
}

function fromFloat(x) {
  if (x !== 0.0) {
    return x;
  }
  
}

function mapFromFloat(x, fn) {
  if (x !== 0.0) {
    return Curry._1(fn, x);
  }
  
}

exports.toString = toString;
exports.fromString = fromString;
exports.toArray = toArray;
exports.fromArray = fromArray;
exports.fromFloat = fromFloat;
exports.mapFromFloat = mapFromFloat;
/* No side effect */
