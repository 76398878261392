// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("bs-platform/lib/js/caml_option.js");

var Switch = { };

var Route = { };

function makeProps(origin, destination, exact, param) {
  var tmp = {
    to: destination
  };
  if (origin !== undefined) {
    tmp.from = Caml_option.valFromOption(origin);
  }
  if (exact !== undefined) {
    tmp.exact = Caml_option.valFromOption(exact);
  }
  return tmp;
}

var Redirect = {
  makeProps: makeProps
};

function makeProps$1(path, className, onClick, children, param) {
  var tmp = {
    to: path,
    className: className,
    children: children
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return tmp;
}

var Link = {
  makeProps: makeProps$1
};

function makeProps$2(path, className, activeClassName, onClick, children, param) {
  var tmp = {
    to: path,
    className: className,
    activeClassName: activeClassName,
    children: children
  };
  if (onClick !== undefined) {
    tmp.onClick = Caml_option.valFromOption(onClick);
  }
  return tmp;
}

var NavLink = {
  makeProps: makeProps$2
};

exports.Switch = Switch;
exports.Route = Route;
exports.Redirect = Redirect;
exports.Link = Link;
exports.NavLink = NavLink;
/* No side effect */
